import { ID } from 'type-core';
import { ComponentType } from 'react';

import { MainPage } from './pages/MainPage';
import { RoutinePage } from './pages/RoutinePage';
import { SessionPage } from './pages/SessionPage';
import { WorkoutPage } from './pages/WorkoutPage';

export type RouterPath =
  | { page: 'root' }
  | { page: 'workout'; id: ID }
  | { page: 'routine'; id: ID }
  | { page: 'session'; id: ID; routineId: ID };

export class Router {
  static routes: Array<{ path: string; component: ComponentType<any> }> = [
    {
      path: '/',
      component: MainPage
    },
    {
      path: '/workout/:workoutId',
      component: WorkoutPage
    },
    {
      path: '/routine/:routineId',
      component: RoutinePage
    },
    {
      path: '/routine/:routineId/session/:sessionId',
      component: SessionPage
    },
    {
      path: '(.*)',
      component: MainPage
    }
  ];
  public static openUrl(url: string): void {
    window.open(url, '_blank');
  }
  public static getPathUrl(path: RouterPath): string {
    switch (path.page) {
      case 'workout': {
        return `/workout/${path.id}`;
      }
      case 'routine': {
        return `/routine/${path.id}`;
      }
      case `session`: {
        return `/routine/${path.routineId}/session/${path.id}`;
      }
      default: {
        return '/';
      }
    }
  }
  public static getBackFromPathUrl(path: RouterPath): string {
    switch (path.page) {
      case 'session': {
        return this.getPathUrl({ page: 'routine', id: path.routineId });
      }
      default: {
        return this.getPathUrl({ page: 'root' });
      }
    }
  }
}
