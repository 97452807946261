import React from 'react';
import {
  Page,
  Navbar,
  Toolbar,
  Link,
  Tabs,
  Tab,
  NavRight,
  Searchbar
} from 'framework7-react';
import { useSource } from 'supersour';

import { Support } from '../Support';
import { RoutinesScene } from '../scenes/RoutinesScene';
import { WorkoutsScene } from '../scenes/WorkoutsScene';
import { ShareSegment } from '../segments/ShareSegment';
import { MainPageController } from './MainPage.controller';

export const MainPage = Support.withUtilities(({ utilities }) => {
  const controller = useSource(React, () => new MainPageController());

  return (
    <Page hideToolbarOnScroll>
      <Navbar>
        <NavRight>
          <Link
            searchbarEnable=".searchbar-demo"
            icon={utilities.icon.getClass('search')}
          />
          <ShareSegment />
        </NavRight>
        <Searchbar
          className="searchbar-demo"
          expandable
          customSearch
          onChange={controller.handleSearchChange}
          onSearchbarClear={controller.handleSearchClear}
          onSearchbarDisable={controller.handleSearchClear}
        />
      </Navbar>
      <Toolbar tabbar labels position="bottom">
        <Link
          text="Routines"
          tabLink="#routines"
          tabLinkActive
          icon={utilities.icon.getClass('routines')}
          onClick={controller.handleRoutinesChange}
        />
        <Link
          text="Workouts"
          tabLink="#workouts"
          icon={utilities.icon.getClass('workouts')}
          onClick={controller.handleWorkoutsChange}
        />
      </Toolbar>
      <Tabs>
        <Tab id="routines" tabActive>
          <RoutinesScene
            search={
              controller.state.tab === 'routines'
                ? controller.state.search
                : undefined
            }
          />
        </Tab>
        <Tab id="workouts">
          <WorkoutsScene
            search={
              controller.state.tab === 'workouts'
                ? controller.state.search
                : undefined
            }
          />
        </Tab>
      </Tabs>
    </Page>
  );
});
