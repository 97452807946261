import { Empty, TypeGuard } from 'type-core';

import { InteractorType, HooksType, Response } from '../definitions';

let globals: HooksType = {};
export class Hooks<I = any, S = any, F = any> {
  public static globals(hooks?: HooksType | Empty): void {
    globals = hooks ? { ...globals, ...hooks } : {};
  }
  #interactor: InteractorType<I, S, F>;
  public constructor(interactor: InteractorType<I, S, F>) {
    this.#interactor = interactor;
  }
  public request(request: I): void {
    this.invoke('onRequest', [request, this.#interactor]);
  }
  public recover(error: Error, request: I): void {
    this.invoke('onRecover', [error, request, this.#interactor]);
  }
  public error(error: Error, request: I): void {
    this.invoke('onError', [error, request, this.#interactor]);
  }
  public response(response: Response<S, F>, request: I): void {
    this.invoke('onResponse', [response, request, this.#interactor]);
  }
  private invoke(key: keyof HooksType, args: any[]): void {
    const method: any = globals[key];
    if (!TypeGuard.isEmpty(method)) {
      method.apply(globals, args);
    }
  }
}
