import { Empty, ID } from 'type-core';
import { into } from 'pipettes';
import { Create } from 'result-box';
import { extract, map } from 'multitude';

import { Failure } from '@utils/Failure';
import { Interactor } from '@utils/interactor';

import { Repository } from '../definitions';
import { WorkoutMappers } from './Workout.mappers';

/* Workout */
export declare namespace Workout {
  interface Deps {
    workout: Repository.Workout;
  }
  interface DTO {
    id: ID;
    name: string;
    description?: string;
    instructor: string;
    intensity: DTO.Intensity;
    category: DTO.Category;
    targets: DTO.Target[];
    equipment: DTO.Equipment[];
    minutes: number;
    videoUrl: string;
    startTime?: number;
    endTime?: number;
  }
  namespace DTO {
    export type Intensity = { id: ID; name: string };
    export type Category = { id: ID; name: string };
    export type Target = { id: ID; name: string };
    export type Equipment = { id: ID; name: string };
  }
}

/* Find */
export declare namespace WorkoutFind {
  type Deps = Workout.Deps;
  type Args = {
    filter?: {
      search?: string;
      intensities?: ID[];
      categories?: ID[];
      targets?: ID[];
    };
  };
  type DTO = Workout.DTO[];
}
export class WorkoutFind extends Interactor<
  WorkoutFind.Args,
  WorkoutFind.DTO,
  Failure<'WorkoutFind'>
> {
  public constructor(deps: WorkoutFind.Deps) {
    super(
      ({ filter }) => {
        return into(
          deps.workout.find(
            filter
              ? {
                  search: filter.search,
                  intensities: filter.intensities
                    ? filter.intensities.map(String)
                    : undefined,
                  categories: filter.categories
                    ? filter.categories.map(String)
                    : undefined,
                  targets: filter.targets
                    ? filter.targets.map(String)
                    : undefined
                }
              : undefined
          ),
          map((arr) => arr.map(WorkoutMappers.toDTO)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'WorkoutFind',
          message: 'There was an error retrieving workouts',
          error,
          data: request
        });
      },
      null
    );
  }
  public toggleManyTargets(targetId: ID): void {
    const args = into(this.request$, extract()) || {};
    if (!args.filter) args.filter = {};
    if (!args.filter.targets) args.filter.targets = [];

    if (args.filter.targets.includes(targetId)) {
      const targets = args.filter.targets.filter((x) => x !== targetId);
      this.use({
        ...args,
        filter: {
          ...args.filter,
          targets: targets.length ? targets : undefined
        }
      });
    } else {
      this.use({
        ...args,
        filter: {
          ...args.filter,
          targets: [...args.filter.targets, targetId]
        }
      });
    }
  }
  public toggleManyCategories(categoryId: ID): void {
    const args = into(this.request$, extract()) || {};
    if (!args.filter) args.filter = {};
    if (!args.filter.categories) args.filter.categories = [];

    if (args.filter.categories.includes(categoryId)) {
      const categories = args.filter.categories.filter((x) => x !== categoryId);
      this.use({
        ...args,
        filter: {
          ...args.filter,
          categories: categories.length ? categories : undefined
        }
      });
    } else {
      this.use({
        ...args,
        filter: {
          ...args.filter,
          categories: [...args.filter.categories, categoryId]
        }
      });
    }
  }
}

/* Get */
export declare namespace WorkoutGet {
  type Deps = Workout.Deps;
  type Args = { id: ID };
  type DTO = Workout.DTO | null;
}
export class WorkoutGet extends Interactor<
  WorkoutGet.Args,
  WorkoutGet.DTO,
  Failure<'WorkoutGet'>
> {
  public constructor(deps: WorkoutGet.Deps) {
    super(
      ({ id }) => {
        return into(
          deps.workout.get(id),
          map((workout) => (workout ? WorkoutMappers.toDTO(workout) : workout)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'WorkoutGet',
          message: 'There was an error retrieving the workout',
          error,
          data: request
        });
      }
    );
  }
}

/* GetCategories */
export declare namespace WorkoutGetCategories {
  type Deps = Workout.Deps;
  type DTO = Workout.DTO.Category[];
}
export class WorkoutGetCategories extends Interactor<
  Empty,
  WorkoutGetCategories.DTO,
  Failure<'WorkoutGetCategories'>
> {
  public constructor(deps: WorkoutGetCategories.Deps) {
    super(
      () => {
        return into(
          deps.workout.getCategories(),
          map((arr) => arr.map(WorkoutMappers.targetToDTO)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'WorkoutGetCategories',
          message: 'There was an error retrieving workout categories',
          error,
          data: request
        });
      }
    );
  }
}

/* GetTargets */
export declare namespace WorkoutGetTargets {
  type Deps = Workout.Deps;
  type DTO = Workout.DTO.Target[];
}
export class WorkoutGetTargets extends Interactor<
  Empty,
  WorkoutGetTargets.DTO,
  Failure<'WorkoutGetTargets'>
> {
  public constructor(deps: WorkoutGetTargets.Deps) {
    super(
      () => {
        return into(
          deps.workout.getTargets(),
          map((arr) => arr.map(WorkoutMappers.targetToDTO)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'WorkoutGetTargets',
          message: 'There was an error retrieving workouts',
          error,
          data: request
        });
      }
    );
  }
}
