import React from 'react';
import { cx } from 'emotion';

import { ChipElement } from '../ChipElement';
import styles from './CardHeaderElement.module.scss';

export declare namespace CardHeaderElement {
  interface Props {
    title: string;
    subtitle?: string;
    chip?: ChipElement.Props;
    children?: React.ReactChild;
    className?: string;
  }
}

export const CardHeaderElement = (
  props: CardHeaderElement.Props
): JSX.Element => (
  <div className={cx(styles.root, props.className)}>
    <div className={styles.row}>
      <div className={styles.title}>{props.title}</div>
      {props.chip && (
        <ChipElement
          {...props.chip}
          className={cx(styles.chip, props.chip.className)}
        />
      )}
    </div>
    {props.subtitle && <div className={styles.subtitle}>{props.subtitle}</div>}
    {props.children}
  </div>
);
