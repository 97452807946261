import React from 'react';
import { cx } from 'emotion';
import { pipe } from 'pipettes';
import { Progressbar } from 'framework7-react';

import { FadeElement } from '../elements/FadeElement';
import styles from './LoadingBar.module.scss';

export declare namespace LoadingBar {
  interface Props {
    active?: boolean;
    duration?: number;
    className?: string;
  }
}

export const LoadingBar = pipe(
  (props: LoadingBar.Props) => ({
    active: false,
    duration: 750,
    ...props
  }),
  (props): JSX.Element => (
    <div className={cx(styles.root, props.className)}>
      <FadeElement in={props.active} duration={props.duration}>
        <Progressbar infinite />
      </FadeElement>
    </div>
  )
);
