import React from 'react';

import { Workout } from '@application';

import { WorkoutPlayerSegment } from '../segments/WorkoutPlayerSegment';
import { CardElement, CardFooterElement } from '../elements/CardElement';
import { BlockElement } from '../elements/BlockElement';
import { HeaderElement } from '../elements/HeaderElement';
import styles from './WorkoutScene.module.scss';

export declare namespace WorkoutScene {
  interface Props {
    workout: Workout.DTO;
  }
}

export const WorkoutScene = (props: WorkoutScene.Props): JSX.Element => (
  <BlockElement left right bottom>
    <HeaderElement
      title={props.workout.name}
      subtitle={'By ' + props.workout.instructor}
      chips={props.workout.targets.map((target) => ({
        items: [target.name],
        shadow: true
      }))}
    />
    <CardElement className={styles.card}>
      <WorkoutPlayerSegment workout={props.workout} />
      <CardFooterElement>
        <span>
          {props.workout.equipment.length
            ? props.workout.equipment.map((x) => x.name).join(', ')
            : 'No equipment'}
        </span>
        <span>{props.workout.minutes + ' min'}</span>
      </CardFooterElement>
    </CardElement>
  </BlockElement>
);
