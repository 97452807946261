import React from 'react';
import { cx } from 'emotion';
import { pipe } from 'pipettes';
import { useResource } from 'supersour';

import { LoadFadeInController } from './LoadFadeIn.controler';
import { FadeElement } from '../elements/FadeElement';
import styles from './LoadFadeIn.module.scss';

export declare namespace LoadFadeIn {
  interface Props {
    children: React.ReactNode;
    duration?: number;
    delay?: number;
    timeout?: number;
    onLoad?: () => void;
    className?: string;
  }
}

export const LoadFadeIn = pipe(
  (props: LoadFadeIn.Props) => ({
    duration: 500,
    delay: 350,
    timeout: 7500,
    onLoad: (): void => undefined,
    ...props
  }),
  (props) => ({
    props,
    controller: useResource(React, () => new LoadFadeInController(props))
  }),
  ({ props, controller }): JSX.Element => {
    return (
      <FadeElement
        in={controller.state.in}
        duration={props.duration}
        className={cx(styles.root, props.className, {
          [styles.out]: !controller.state.in
        })}
      >
        {props.children}
      </FadeElement>
    );
  }
);
