/* eslint-disable dot-notation */
import { ID } from 'type-core';
import { into } from 'pipettes';
import { LazyPromist } from 'promist';
import { Push, combine, fromPromise, map, switchMap } from 'multitude';

import { Repository } from '@application';

export class WorkoutRepository implements Repository.Workout {
  private data: Promise<any>;
  public constructor(getData: () => Promise<any>) {
    this.data = LazyPromist.from(getData);
  }
  public get(id: ID): Push.Observable<Repository.Workout.Structure | null> {
    return into(
      fromPromise(this.data),
      map((data) => data.byId[id]),
      map((item) => item || null)
    );
  }
  public find(
    filters?: Repository.Workout.Filter
  ): Push.Observable<Repository.Workout.Structure[]> {
    return into(
      fromPromise(this.data),
      map((data) => Object.keys(data.byId)),
      switchMap((ids) => {
        return into(
          combine(ids.map((id) => this.get(id))),
          map((arr) => {
            return arr.filter((item): item is Repository.Workout.Structure => {
              return Boolean(item);
            });
          }),
          map((items) => {
            if (!filters) return items;

            const { search, intensities, categories, targets } = filters;

            if (intensities) {
              items = items.filter((item) =>
                intensities.includes(item.intensity)
              );
            }
            if (categories) {
              items = items.filter((item) =>
                categories.includes(item.category)
              );
            }
            if (targets) {
              // item must have all targets
              items = items.filter((item) => {
                for (const selectedTarget of targets) {
                  if (!item.targets.includes(selectedTarget)) {
                    return false;
                  }
                }
                return true;
              });
            }

            if (search) {
              const lowerSearch = search.toLowerCase().trim();
              items = items.filter((item) => {
                return item.name.toLowerCase().includes(lowerSearch);
              });
            }

            return items;
          })
        );
      })
    );
  }
  public getIntensities(): Push.Observable<string[]> {
    return into(
      fromPromise(this.data),
      map((data) => data.intensities)
    );
  }
  public getCategories(): Push.Observable<string[]> {
    return into(
      fromPromise(this.data),
      map((data) => data.categories)
    );
  }
  public getTargets(): Push.Observable<string[]> {
    return into(
      fromPromise(this.data),
      map((data) => data.targets)
    );
  }
}
