import React from 'react';
import { cx } from 'emotion';

import { YoutubePlayer } from './YoutubePlayer';
import styles from './Player.module.scss';

export declare namespace Player {
  interface Props {
    videoService: 'youtube';
    videoId: string;
    start?: number;
    end?: number;
    onReady?: () => void;
    onPlay?: (play: boolean) => void;
    className?: string;
  }
}

export const Player = (props: Player.Props): JSX.Element => (
  <div className={cx(styles.root, props.className)}>
    <div className={styles.container}>
      <YoutubePlayer
        videoId={props.videoId}
        start={props.start}
        end={props.end}
        onReady={props.onReady}
        onPlay={props.onPlay}
      />
    </div>
  </div>
);
