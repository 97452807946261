/* Fonts & Styles */
import './styles/index.scss';

/* React */
import React from 'react';
import ReactDOM from 'react-dom';

/* App */
import { App } from './App';
import { connect, ExpositionDeps } from './connect';

export function render(deps: ExpositionDeps): void {
  connect(deps);
  ReactDOM.render(React.createElement(App), document.getElementById('root'));
}
