import React, { FC } from 'react';

import { RoutineGetWithSessions } from '@application';

import { Router } from '../Router';
import { HeaderElement } from '../elements/HeaderElement';
import { BlockElement } from '../elements/BlockElement';
import { ChipElement } from '../elements/ChipElement';
import {
  CardElement,
  CardHeaderElement,
  CardFooterElement
} from '../elements/CardElement';
import styles from './RoutineScene.module.scss';

export declare namespace RoutineScene {
  interface Props {
    routine: RoutineGetWithSessions.DTO;
  }
}

export const RoutineScene: FC<RoutineScene.Props> = (props) => {
  return (
    <>
      <BlockElement className={styles.header} left right>
        <HeaderElement
          title={props.routine.name}
          chips={props.routine.targets
            .map(
              (target): ChipElement.Props => ({
                items: [target.name],
                shadow: true
              })
            )
            .concat({
              items: [props.routine.level.name],
              invert: true,
              shadow: false
            })}
        />
      </BlockElement>
      {props.routine.sessions.map((session, i) => (
        <BlockElement key={i} xy>
          <CardElement
            href={Router.getPathUrl({
              page: 'session',
              id: session.id,
              routineId: props.routine.id
            })}
          >
            <CardHeaderElement title={'Session ' + (i + 1)} />
            <CardFooterElement>
              <span>{session.workouts.length} workouts</span>
              <span>{`${session.minutes} min`}</span>
            </CardFooterElement>
          </CardElement>
        </BlockElement>
      ))}
    </>
  );
};
