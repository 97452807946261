import forage from 'localforage';

import { Service } from '../../components/definitions';

export class PersistenceService implements Service.Persistence {
  private keys: void | Set<string>;
  public async has(key: string): Promise<boolean> {
    if (this.keys) return this.keys.has(key);

    this.keys = new Set(await forage.keys());
    return this.has(key);
  }
  public async get<T = any>(key: string): Promise<T> {
    return (await forage.getItem(key)) as T;
  }
  public async set<T = any>(key: string, value: T): Promise<void> {
    if (this.keys) this.keys.add(key);
    await forage.setItem(key, value);
  }
  public async delete(key: string): Promise<void> {
    if (this.keys) this.keys.delete(key);
    await forage.removeItem(key);
  }
}
