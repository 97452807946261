import { Push } from 'multitude';
import { until } from 'promist';
import { ResourceEnclosure } from 'supersour';

import { Service, Utility } from '../../components/definitions';

export interface WorkerServiceDeps {
  logger: Utility.Logger;
}

export declare namespace WorkerService {
  interface Options {
    enable: boolean;
    publicUrl: string;
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
  }
}

export class WorkerService
  extends ResourceEnclosure<Service.Worker.State, WorkerServiceDeps>
  implements Service.Worker {
  private options: WorkerService.Options;
  public constructor(deps: WorkerServiceDeps, options: WorkerService.Options) {
    super(
      { enabled: false, registered: false, updatePending: false },
      deps,
      () => {
        this.next({ enabled: true });
        this.register()
          .then(() => {
            this.next({ registered: true });
            this.deps.logger.info('Service Worker registered');
          })
          .catch((err) => this.deps.logger.error(err));

        return () => {
          this.next({ enabled: false });
          this.unregister()
            .then(() => {
              this.next({ registered: false });
              this.deps.logger.info('Service Worker unregistered');
            })
            .catch((err) => this.deps.logger.error(err));
        };
      }
    );
    this.options = options;
    this.options.enable ? this.enable() : this.disable();
  }
  public get state$(): Push.Observable<Service.Worker.State> {
    return super.state$;
  }
  public async update(): Promise<void> {
    this.next({ updatePending: false });

    if (!navigator.serviceWorker) return;

    const registration = await navigator.serviceWorker.ready;
    if (!registration.waiting) return;

    const promise = new Promise<void>((resolve) => {
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        function listener() {
          navigator.serviceWorker.removeEventListener(
            'controllerchange',
            listener
          );
          return resolve();
        }
      );
    });

    registration.waiting.postMessage({ type: 'SKIP_WAITING' });

    return promise;
  }
  private async register(): Promise<void> {
    if (!('serviceWorker' in navigator)) {
      throw Error(`Service Worker API is unavailable`);
    }

    const publicUrl = new URL(this.options.publicUrl, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Service Worker won't work if the public url origin is
      // different from what our page is served on.
      // This might happen if a CDN is used to serve assets.
      throw Error(`Application public url doesn't match location`);
    }

    await until(() => document.readyState === 'complete');

    const serviceWorkerUrl = this.options.publicUrl + '/service-worker.js';
    const isLocalhost = Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

    if (isLocalhost) {
      // This is running on localhost.
      // Check if a service worker still exists or not.
      await this.checkValidServiceWorker(serviceWorkerUrl);
    } else {
      // Is not localhost, register service worker.
      await this.registerValidServiceWorker(serviceWorkerUrl);
    }
  }
  private async unregister(): Promise<void> {
    if (!('serviceWorker' in navigator)) {
      throw Error(`Service Worker API is unavailable`);
    }

    await navigator.serviceWorker.ready.then((registration) =>
      registration.unregister()
    );
  }
  private async checkValidServiceWorker(
    serviceWorkerUrl: string
  ): Promise<void> {
    // Check if the service worker can be found.
    // If it can't reload the page.
    return fetch(serviceWorkerUrl)
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          return navigator.serviceWorker.ready.then((registration) => {
            return registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          return this.registerValidServiceWorker(serviceWorkerUrl);
        }
      })
      .catch(() => {
        return Promise.reject(
          Error(`No internet connection found. App is running in offline mode.`)
        );
      });
  }
  private async registerValidServiceWorker(
    serviceWorkerUrl: string
  ): Promise<void> {
    const registration = await navigator.serviceWorker.register(
      serviceWorkerUrl
    );

    registration.onupdatefound = async () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }

      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            this.deps.logger.info('Service Worker update available');
            this.next({ updatePending: true });
          } else {
            this.deps.logger.info('Service Worker success');
          }
        }
      };
    };
  }
}
