import { SuperResource } from 'supersour';

export declare namespace LoadFadeInController {
  interface State {
    in: boolean;
  }

  interface Deps {
    timeout: number;
    delay: number;
    onLoad: () => void;
  }
}

export class LoadFadeInController extends SuperResource<
  LoadFadeInController.State,
  LoadFadeInController.Deps
> {
  private interval: null | NodeJS.Timer;
  public constructor(deps: LoadFadeInController.Deps) {
    super({ in: false }, deps, () => {
      const start = Date.now();
      this.interval = setInterval(() => {
        if (
          document.readyState === 'complete' ||
          Date.now() - start > this.deps.timeout
        ) {
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
          }
          setTimeout(() => {
            this.deps.onLoad();
            this.next({ in: true });
          }, this.deps.delay);
        }
      }, 75);

      return () => {
        if (this.interval) clearInterval(this.interval);
      };
    });
  }
}
