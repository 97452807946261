import React from 'react';
import {
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton
} from 'framework7-react';

import { Support } from '../Support';
import { Services } from '../definitions';

export declare namespace Consent {
  interface Props {
    shouldOpen: boolean;
  }
}

interface ConsentProps extends Consent.Props {
  services: Services;
}

export const Consent = Support.withServices((props: ConsentProps) => (
  <Actions
    opened={props.shouldOpen}
    closeByOutsideClick={false}
    closeByBackdropClick={false}
    onActionsClosed={() => null}
  >
    <ActionsGroup>
      <ActionsLabel>
        We use cookies and similar technologies for functional and analytical
        purposes.
      </ActionsLabel>
      <ActionsButton
        onClick={() => props.services.consent.update({ analytics: true })}
      >
        Accept
      </ActionsButton>
    </ActionsGroup>
  </Actions>
));
