import { Push, Observable, Subject } from 'multitude';
import { theming } from 'variable-theming';
import { injectGlobal } from 'emotion';

import { Utility } from '../../components/definitions';

export declare namespace ThemeUtility {
  interface Options {
    default: Utility.Theme.Variant;
    themes: Record<Utility.Theme.Variant, Utility.Theme.Structure>;
  }
}

export class ThemeUtility implements Utility.Theme {
  private options: ThemeUtility.Options;
  private subject: Push.Subject<Utility.Theme.Structure>;
  public constructor(options: ThemeUtility.Options) {
    this.options = options;
    this.subject = new Subject({ replay: true });
    this.setVariant(options.default);
  }
  public get theme$(): Push.Observable<Utility.Theme.Structure> {
    return Observable.from(this.subject);
  }
  public setVariant(variant: Utility.Theme.Variant): void {
    const theme = this.options.themes[variant];
    injectGlobal({ ':root': theming(theme).styles });
    this.subject.next(theme);
  }
}
