import React from 'react';
import { ID } from 'type-core';
import { cx } from 'emotion';

import { ChipElement } from './ChipElement';
import { BlockElement } from './BlockElement';
import styles from './ChipSelect.module.scss';

export declare namespace ChipSelect {
  interface Props {
    items: Record<ID, { name: string; active: boolean }>;
    onSelect?: (key: ID) => void;
    chip?: ChipElement.Props;
    className?: string;
  }
}

export const ChipSelect = (props: ChipSelect.Props): JSX.Element => (
  <BlockElement className={cx(styles.root, props.className)}>
    {Object.entries(props.items).map(([key, value]) => (
      <ChipElement
        key={key}
        items={[value.name]}
        invert={value.active}
        shadow={!value.active}
        onClick={() => props.onSelect && props.onSelect(key)}
        {...props.chip}
        className={cx(styles.chip, props.chip && props.chip.className)}
      />
    ))}
  </BlockElement>
);
