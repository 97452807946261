import { Members, ValueOf } from 'type-core';
import {
  Push,
  Observable,
  map,
  combine as obsCombine,
  share,
  start,
  compare
} from 'multitude';
import { into } from 'pipettes';

import {
  InteractorType,
  Response,
  SuccessType,
  FailureType
} from '../definitions';
import { flush } from './flush';

export declare namespace Composite {
  interface Options<B extends boolean = boolean> {
    flush?: B;
  }

  type ResponseType<
    T extends Members<InteractorType>,
    B extends boolean = boolean
  > = B extends true
    ? Response<SuccessTypes<T>, FailureTypes<T>> | null
    : Response<SuccessTypes<T>, FailureTypes<T>>;

  type SuccessTypes<T extends Members<InteractorType>> = {
    [P in keyof T]: SuccessType<T[P]>;
  };

  type FailureTypes<T extends Members<InteractorType>> = ValueOf<
    { [P in keyof T]: FailureType<T[P]> }
  >;
}

export function composite<
  T extends Members<InteractorType>,
  B extends boolean = boolean
>(
  interactors: T,
  options?: Composite.Options<B>
): Push.Observable<Composite.ResponseType<T, B>> {
  const opts = options || {};
  const responses: Members<Push.Observable<Response | null>> = {};

  for (const [key, interactor] of Object.entries(interactors)) {
    responses[key] = opts.flush ? flush(interactor) : interactor.response$;
  }

  return into(
    obsCombine(responses),
    map((results) => {
      const data: Members = {};

      let isNull = false;
      for (const [key, result] of Object.entries(results)) {
        if (!result) isNull = true;
        else if (result.success) data[key] = result.data;
        else return result;
      }

      return isNull ? null : { success: true as true, data };
    }),
    (observable) => {
      return opts.flush
        ? into(
            observable,
            compare((before, current) => before === null && before === current),
            start(null, 'no-emit')
          )
        : observable;
    },
    share({ policy: 'on-demand', replay: true }),
    Observable.from
  ) as Push.Observable<Composite.ResponseType<T, B>>;
}
