import React from 'react';
import { cx } from 'emotion';

import styles from './TitleElement.module.scss';

export declare namespace TitleElement {
  interface Props {
    kind: 'large' | 'medium' | 'subtitle' | 'section';
    children: React.ReactNode | React.ReactNode[];
    className?: string;
  }
}

export const TitleElement = ({
  kind,
  children,
  className
}: TitleElement.Props): JSX.Element | null => {
  switch (kind) {
    case 'large': {
      return <h1 className={cx(styles.large, className)}>{children}</h1>;
    }
    case 'medium': {
      return <h1 className={cx(styles.medium, className)}>{children}</h1>;
    }
    case 'subtitle': {
      return <h2 className={cx(styles.subtitle, className)}>{children}</h2>;
    }
    case 'section': {
      return <h3 className={cx(styles.section, className)}>{children}</h3>;
    }
    default: {
      return null;
    }
  }
};
