import Framework7 from 'framework7';
import { wait } from 'promist';
import Queue from 'p-queue';

import { Service, Utility } from '../../components/definitions';

export declare namespace NotificationService {
  interface Deps {
    f7: Promise<Framework7>;
    icon: Utility.Icon;
    logger: Utility.Logger;
  }

  interface Options {
    feedbackCloseMs: number;
  }
}

export class NotificationService implements Service.Notification {
  private deps: NotificationService.Deps;
  private options: NotificationService.Options;
  private queue: Queue;
  public constructor(
    deps: NotificationService.Deps,
    options: NotificationService.Options
  ) {
    this.deps = deps;
    this.options = options;
    this.queue = new Queue({ concurrency: 1 });
  }
  public async push(
    notification: Service.Notification.Structure
  ): Promise<Service.Notification.Response> {
    return this.queue.add(() => this.process(notification));
  }
  private async process(
    notification: Service.Notification.Structure
  ): Promise<Service.Notification.Response> {
    const f7 = await this.deps.f7;
    return new Promise((resolve) => {
      switch (notification.type) {
        case 'alert': {
          f7.dialog.alert(notification.text, notification.title, () =>
            resolve({ continue: true })
          );
          break;
        }
        case 'confirm': {
          f7.dialog.confirm(
            notification.text,
            notification.title,
            () => resolve({ continue: true }),
            () => resolve({ continue: false })
          );
          break;
        }
        case 'feedback': {
          const icon = this.deps.icon.getClass(notification.icon);
          const toast = f7.toast.create({
            text: notification.title,
            position: 'center',
            icon: `<i class="${icon}"></i>`,
            destroyOnClose: true
          });
          toast.open();

          wait(this.options.feedbackCloseMs)
            .then(() => {
              toast.close();
              resolve({ continue: true });
            })
            .catch((err) => this.deps.logger.error(err));

          break;
        }
        default: {
          const type = (notification as any).type;
          this.deps.logger.error(Error(`Invalid notification type: ${type}`));
        }
      }
    });
  }
}
