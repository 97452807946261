/* eslint-disable @typescript-eslint/no-use-before-define */
import { Collection, env } from 'ensurism';
import { levels } from 'loglevel';

export default new Collection(
  {
    env: env.ensure('NODE_ENV', {
      type: 'string',
      default: 'development',
      enum: ['development', 'production', 'test']
    })
  },
  (vars) => ({
    env: vars.get('env'),
    appId: env.assert('REACT_APP_ID'),
    publicUrl: env.assert('PUBLIC_URL'),
    logger: vars.select('env', {
      default: levels.WARN,
      development: levels.TRACE
    }),
    manifest: {
      name: env.assert('REACT_APP_NAME'),
      short_name: env.assert('REACT_APP_SHORT_NAME'),
      description: env.assert('REACT_APP_DESCRIPTION')
    },
    breakpoints: {
      xs: env.coerce('REACT_APP_XS', true, 'number'),
      sm: env.coerce('REACT_APP_SM', true, 'number'),
      md: env.coerce('REACT_APP_MD', true, 'number'),
      lg: env.coerce('REACT_APP_LG', true, 'number'),
      xl: env.coerce('REACT_APP_XL', true, 'number')
    },
    serviceWorker: vars.select('env', {
      default: false,
      production: true
    })
  })
).all();
