import React from 'react';
import { Empty } from 'type-core';
import { cx, css } from 'emotion';
import { into, pipe } from 'pipettes';
import { App } from 'framework7-react';
import { Consume, Operate } from 'result-box';
import { compare, fromEvent, map, useObservable } from 'multitude';

import styles from './Application.module.scss';

export declare namespace Application {
  interface Props {
    children: React.ReactNode | React.ReactNode[];
    params: Parameters;
    className?: string;
  }
  type Parameters = Exclude<App.Props['params'], Empty>;
}

const variables = (height?: number): string => css`
  --application-height: ${height ? `${height}px` : '100vh'};
`;

/*
  We're setting the height programmatically as the lower bar in Safari
  doesn't have an effect over css vh; otherwise the layout will get cropped at the bottom.
*/
export const Application = pipe(
  (props: Application.Props) => ({
    ...props,
    height: useObservable(
      React,
      () => {
        return into(
          fromEvent(window, 'resize'),
          map(() => window.innerHeight),
          compare()
        );
      },
      pipe(Operate.fallback(window.innerHeight), Consume.result)
    )
  }),
  (props): JSX.Element => {
    return (
      <App
        params={props.params}
        className={cx(styles.root, props.className, variables(props.height))}
      >
        {props.children}
      </App>
    );
  }
);
