/* eslint-disable no-console */
import { MaybePromise } from 'type-core';
import { into } from 'pipettes';
import { until } from 'promist';
import { extract } from 'multitude';
import { ensure, Exception } from 'errorish';
import { f7, f7ready } from 'framework7-react';

import { Repositories } from '@application';
import { Failure } from '@utils/Failure';

import { Configuration, Services, Utilities } from './definitions';
import { Support } from './Support';

export interface ExpositionDeps {
  configuration: MaybePromise<Configuration>;
  utilities: MaybePromise<Utilities>;
  services: MaybePromise<Services>;
  repositories: MaybePromise<Repositories>;
}

export function connect(deps: ExpositionDeps): void {
  Promise.resolve()
    .then(() => support(deps))
    .then(() => errors())
    .then(() => f7router())
    .then(() => Object.assign(window, { Support }))
    .catch(Support.setFatal);
}

function support(deps: ExpositionDeps): Promise<any> {
  return Promise.all([
    /* Configuration */
    Promise.resolve(deps.configuration).then(Support.setConfiguration),
    /* Utilities */
    Promise.resolve(deps.utilities).then(Support.setUtilities),
    /* Services */
    Promise.resolve(deps.services).then(Support.setServices),
    /* Repositories */
    Promise.resolve(deps.repositories).then(Support.setRepositories)
  ]);
}

function errors(): void {
  Support.failure$.subscribe((failure) => {
    const utilities = into(Support.utilities$, extract());
    const services = into(Support.services$, extract());

    const error = Failure.is(failure)
      ? Exception.from(failure)
      : ensure(failure);
    utilities ? utilities.logger.error(error) : console.error(error);

    if (Failure.is(failure)) {
      services
        ? services.notification.push({
            type: 'alert',
            title: 'Error',
            text: failure.message
          })
        : window.alert(failure.message);
    }
  });

  Support.fatal$.subscribe((fatal) => {
    const utilities = into(Support.utilities$, extract());
    const services = into(Support.services$, extract());

    const error = Failure.is(fatal) ? Exception.from(fatal) : ensure(fatal);
    utilities ? utilities.logger.error(error) : console.error(error);

    if (Failure.is(fatal)) {
      if (services) {
        services.notification
          .push({
            type: 'alert',
            title: 'Error',
            text: fatal.message
          })
          .then(
            () => window.location.reload(),
            () => window.location.reload()
          );
      } else {
        window.alert(fatal.message);
        window.location.reload();
      }
    }
  });
}

// Fixes `< Back` on iOS when starting point is an inner page.
async function f7router(): Promise<void> {
  await new Promise((resolve) => f7ready(resolve));
  await until(() => Boolean(f7.views.main), true);

  f7.views.main.router.history = [];
  f7.views.main.router.back = into(
    f7.views.main.router,
    (router) => ({ router, back: router.back.bind(router) }),
    ({ router, back }) => (url?: any, options?: any): any => {
      if (url && typeof url === 'string' && url.replace(/^#$/, '')) {
        if (router.history.includes(url)) {
          return back(url, options);
        } else {
          router.clearPreviousHistory();
          return router.navigate(url, options);
        }
      } else {
        if (router.history.length < 2) {
          router.clearPreviousHistory();
          return router.navigate('/', options);
        } else {
          return back(url, options);
        }
      }
    }
  );
}
