import { into } from 'pipettes';

import { Utilities } from '../components/definitions';
import {
  BreakpointUtility,
  DeviceUtility,
  IconUtility,
  LoggerUtility,
  ThemeUtility
} from '../instruments';
import themes from './themes';
import env from './env';

export function utilities(): Utilities {
  return into(
    { logger: new LoggerUtility({ level: env.logger }) },
    (utils) => ({
      ...utils,
      icon: new IconUtility(utils),
      breakpoint: new BreakpointUtility({ breakpoints: env.breakpoints }),
      device: new DeviceUtility(),
      theme: new ThemeUtility({ default: 'main', themes })
    })
  );
}
