import { SuperResource } from 'supersour';
import { fromEvent } from 'multitude';
import { pipe } from 'pipettes';

interface InternalState {
  href: string;
  isParent: boolean;
  windowWidth: number;
  windowHeight: number;
}

export declare namespace FrameController {
  interface State extends InternalState {
    skipContainer: boolean;
    containerWidth: number;
    appWidth: number;
    appLeft: number;
    infoWidth: number;
  }
  interface Deps {
    breakpoint: number;
    appWidthPc: number;
    appMaxWidthPx: number;
    containerMaxWidthPx: number;
  }
}

export class FrameController extends SuperResource<
  InternalState,
  FrameController.Deps,
  FrameController.State
> {
  public constructor(deps: FrameController.Deps) {
    super(
      {
        href: window.location.href,
        isParent: window === window.parent,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      },
      deps,
      () => [
        fromEvent(window, 'resize').subscribe(() => {
          const dimensions = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
          };
          this.next(dimensions);
        })
      ],
      pipe(
        (state) => ({
          ...state,
          skipContainer: state.windowWidth < deps.breakpoint,
          containerWidth: Math.min(state.windowWidth, deps.containerMaxWidthPx)
        }),
        (state) => ({
          ...state,
          appWidth: Math.min(
            state.containerWidth * (deps.appWidthPc / 100),
            deps.appMaxWidthPx
          ),
          appLeft:
            state.windowWidth > state.containerWidth
              ? (state.windowWidth - state.containerWidth) / 2
              : 0
        }),
        (state) => ({
          ...state,
          infoWidth: state.containerWidth - state.appWidth
        })
      )
    );
  }
}
