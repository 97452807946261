import { Empty, TypeGuard } from 'type-core';

export declare namespace Failure {
  /** An identifying label for failures to indicate their scope or origin */
  type Label = string | null;
  /** An failure-like object */
  interface Like<L extends Label = Label, D = any> {
    label: L;
    message: string;
    error?: Error;
    data: D;
  }
  interface Noticeable {
    label?: string | null;
    message: string;
  }
}

export class Failure<L extends Failure.Label = Failure.Label, D = any>
  implements Failure.Like<L, D> {
  /**
   * Tests whether an item is an instance of the `Failure` class
   * or any class inheriting from it.
   * When `item` is a `Failure`, it will optionally test
   * for the instance also having a specific `label`.
   */
  public static is<L extends Failure.Label = Failure.Label>(
    item: any,
    label?: L
  ): item is Failure<L> {
    if (!(item instanceof Failure)) return false;
    if (TypeGuard.isEmpty(label)) return true;
    return item.label === label;
  }
  /**
   * Creates a `Failure` from any failure-like object.
   * When `item` is a `Failure`, a new instance will not be created.
   */
  public static from<L extends Failure.Label, D>(
    item: Failure.Like<L, D>
  ): Failure<L, D> {
    return Failure.is(item)
      ? item
      : new Failure([item.label, item.message], item.error, item.data);
  }
  /**
   * Returns a string that combines an optional `label` and a required`message` string for an object.
   */
  public static notice(item: Failure.Noticeable): string {
    if (!item.label) return item.message;
    return item.message ? `[${item.label}] ${item.message}` : `[${item.label}]`;
  }
  /** An optional label that identifies the failure. */
  public label: L;
  /** A failure message. */
  public message: string;
  public data: D;
  /** A source error. */
  public error: Error;
  /**
   * @param notice a message string or an array containing a label and a message
   * @param error an optional source error
   * @param data an optional data field
   */
  public constructor(
    notice: string | [L, string],
    error?: Error | Empty,
    data?: D
  ) {
    const [label, message] = TypeGuard.isArray(notice)
      ? notice
      : [null, notice];

    this.message = message;
    this.label = label as L;
    this.data = data as D;
    this.error = TypeGuard.isEmpty(error) ? new Error(message) : error;
  }
}
