import { ID } from 'type-core';
import { SuperResource } from 'supersour';
import { Push, debounce, extract } from 'multitude';
import { Operate, Result } from 'result-box';
import { into } from 'pipettes';

import {
  Repository,
  Workout,
  WorkoutFind,
  WorkoutGetCategories,
  WorkoutGetTargets
} from '@application';
import { composite } from '@utils/interactor';
import { Failure } from '@utils/Failure';

import { Support } from '../Support';
import { Service } from '../definitions';

export declare namespace WorkoutsSceneController {
  interface State {
    workouts: Workout.DTO[];
    targets: Record<ID, { name: string; active: boolean }>;
    categories: Record<ID, { name: string; active: boolean }>;
  }
  interface Deps {
    search$: Push.Observable<string | undefined>;
    workout: Repository.Workout;
    notifications: Service.Notification;
  }
}

export class WorkoutsSceneController extends SuperResource<
  Result.Break<WorkoutsSceneController.State, Failure>,
  WorkoutsSceneController.Deps
> {
  private interactors: {
    find: WorkoutFind;
    targets: WorkoutGetTargets;
    categories: WorkoutGetCategories;
  };
  public constructor(deps: WorkoutsSceneController.Deps) {
    super(null, deps, () => {
      this.interactors.targets.use(null);
      this.interactors.categories.use(null);
      return [
        into(deps.search$, debounce(250)).subscribe((search) => {
          this.interactors.find.use({ filter: { search } });
        }),
        composite(this.interactors, { flush: true }).subscribe(
          (combination) => {
            const state = into(
              combination,
              Operate.tap(null, Support.setFatal),
              Operate.map((result) => {
                const state: WorkoutsSceneController.State = {
                  workouts: result.find,
                  targets: {},
                  categories: {}
                };
                for (const target of result.targets) {
                  state.targets[target.id] = {
                    name: target.name,
                    active: this.isTargetActive(target.id)
                  };
                }
                for (const level of result.categories) {
                  state.categories[level.id] = {
                    name: level.name,
                    active: this.isCategoryActive(level.id)
                  };
                }

                return state;
              })
            );

            this.next(state);
          }
        )
      ];
    });

    this.interactors = {
      find: new WorkoutFind(deps),
      targets: new WorkoutGetTargets(deps),
      categories: new WorkoutGetCategories(deps)
    };
  }
  public toggleManyTargets = (targetId: ID): void => {
    this.interactors.find.toggleManyTargets(targetId);
  };
  public toggleManyCategories = (levelId: ID): void => {
    this.interactors.find.toggleManyCategories(levelId);
  };
  private isTargetActive(targetId: ID): boolean {
    const args = into(this.interactors.find.request$, extract());
    if (!args || !args.filter || !args.filter.targets) return false;
    return args.filter.targets.includes(targetId);
  }
  private isCategoryActive(categoryId: ID): boolean {
    const args = into(this.interactors.find.request$, extract());
    if (!args || !args.filter || !args.filter.categories) return false;
    return args.filter.categories.includes(categoryId);
  }
}
