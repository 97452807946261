import React from 'react';

import { ChipElement } from './ChipElement';
import { TitleElement } from './TitleElement';
import styles from './HeaderElement.module.scss';

export declare namespace HeaderElement {
  interface Props {
    title: string;
    subtitle?: string;
    chips: ChipElement.Props[];
  }
}

export const HeaderElement = (props: HeaderElement.Props): JSX.Element => (
  <div className={styles.root}>
    <div className={styles.titles}>
      <TitleElement kind="medium">{props.title}</TitleElement>
      {props.subtitle && (
        <TitleElement kind="subtitle">{props.subtitle}</TitleElement>
      )}
    </div>
    <div className={styles.chips}>
      {props.chips.map((chip, i) => (
        <ChipElement key={i} {...chip} />
      ))}
    </div>
  </div>
);
