import React from 'react';
import { ID } from 'type-core';
import { Operate } from 'result-box';
import { usePropsMulticast } from 'multitude';
import { Page, Navbar, NavRight, NavTitle } from 'framework7-react';

import { Repositories, WorkoutGet } from '@application';
import { useInteractor } from '@utils/interactor';

import { Router } from '../Router';
import { Support } from '../Support';
import { Logo } from '../elements/Logo';
import { Preloader } from '../framework/Preloader';
import { WorkoutScene } from '../scenes/WorkoutScene';
import { ShareSegment } from '../segments/ShareSegment';

export declare namespace WorkoutPage {
  interface Props {
    workoutId: ID;
  }
}

interface WorkoutPageProps extends WorkoutPage.Props {
  repositories: Repositories;
}

export const WorkoutPage = Support.withRepositories<WorkoutPageProps>(
  (props) => {
    const { interactor, response } = useInteractor(
      React,
      () => new WorkoutGet(props.repositories),
      Operate.transform(null, Support.setFailure),
      { flush: true }
    );

    usePropsMulticast(React, props.workoutId, (id) => interactor.use({ id }));

    return (
      <Page>
        <Navbar
          backLink
          backLinkUrl={Router.getBackFromPathUrl({
            page: 'workout',
            id: props.workoutId
          })}
        >
          <NavTitle>
            <Logo width={60} variant="surface-contrast" />
          </NavTitle>
          <NavRight>
            <ShareSegment />
          </NavRight>
        </Navbar>
        {response ? <WorkoutScene workout={response} /> : <Preloader />}
      </Page>
    );
  }
);
