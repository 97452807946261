import { Repositories } from '@application';
import { RoutineRepository } from '@infrastructure/Routine.repository';
import { SessionRepository } from '@infrastructure/Session.repository';
import { WorkoutRepository } from '@infrastructure/Workout.repository';

export async function repositories(): Promise<Repositories> {
  return {
    routine: new RoutineRepository(async () => {
      const data = await import('@assets/data.json');
      return data.routines;
    }),
    session: new SessionRepository(async () => {
      const data = await import('@assets/data.json');
      return data.sessions;
    }),
    workout: new WorkoutRepository(async () => {
      const data = await import('@assets/data.json');
      return data.workouts;
    })
  };
}
