import { ID } from 'type-core';
import { into } from 'pipettes';
import { Create } from 'result-box';
import { Observable, map, switchMap } from 'multitude';

import { Failure } from '@utils/Failure';
import { Interactor } from '@utils/interactor';

import { Repository } from '../definitions';
import { SessionMappers } from './Session.mappers';
import { Workout, WorkoutGet } from './Workout.interactors';

/* Session */
export declare namespace Session {
  interface Deps {
    session: Repository.Session;
  }
  interface DTO {
    id: ID;
    name: string;
    minutes: number;
    workouts: Workout.DTO[];
  }
}

/* Get */
export declare namespace SessionGet {
  type Deps = Session.Deps & { getWorkout: WorkoutGet };
  type Args = { id: ID };
  type DTO = Session.DTO | null;
}
export class SessionGet extends Interactor<
  SessionGet.Args,
  SessionGet.DTO,
  Failure<'SessionGet'>
> {
  public constructor(deps: SessionGet.Deps) {
    super(
      ({ id }) => {
        return into(
          Observable.from(deps.session.get(id)),
          switchMap((session) => {
            return session
              ? SessionMappers.toDTOStream(session, deps.getWorkout)
              : Observable.of(null);
          }),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'SessionGet',
          message: 'There was an error retrieving a session',
          error,
          data: request
        });
      }
    );
  }
}
