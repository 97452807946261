import React from 'react';
import { useSource } from 'supersour';

import { Workout } from '@application';

import { Preloader } from '../framework/Preloader';
import { CardContentElement } from '../elements/CardElement';
import { ChipElement } from '../elements/ChipElement';
import { FadeElement } from '../elements/FadeElement';
import { Player } from '../elements/Player';
import { WorkoutPlayerSegmentController } from './WorkoutPlayerSegment.controller';
import styles from './WorkoutPlayerSegment.module.scss';

export declare namespace WorkoutPlayerSegment {
  interface Props {
    workout: Workout.DTO;
  }
}

export const WorkoutPlayerSegment: React.FC<WorkoutPlayerSegment.Props> = (
  props
) => {
  const controller = useSource(
    React,
    () => new WorkoutPlayerSegmentController()
  );

  return (
    <>
      {controller.state.ready ? null : <Preloader />}
      <CardContentElement className={styles.root}>
        <Player
          className={styles.player}
          videoService="youtube"
          videoId={props.workout.videoUrl.split('=').pop() as string}
          start={props.workout.startTime}
          end={props.workout.endTime}
          onReady={controller.handleReady}
          onPlay={controller.handlePlay}
        />
        <FadeElement
          in={!controller.state.play}
          duration={300}
          className={styles.chip}
        >
          <ChipElement
            items={[props.workout.intensity.name, props.workout.category.name]}
          />
        </FadeElement>
      </CardContentElement>
    </>
  );
};
