import { Push, compare, Observable, omit, Subject } from 'multitude';
import Queue from 'p-queue';
import { into } from 'pipettes';
import remote from 'remote-script';

import { Service, Utility } from '../../components/definitions';

export declare namespace ConsentService {
  interface Deps {
    logger: Utility.Logger;
    persistence: Service.Persistence;
  }
  interface Options {
    persistKey: string;
    analyticsJsUrl: string | null;
  }
}

export class ConsentService implements Service.Consent {
  private deps: ConsentService.Deps;
  private options: ConsentService.Options;
  private queue: Queue;
  private subject: Push.Subject<Service.Consent.State | null>;
  public constructor(
    deps: ConsentService.Deps,
    options: ConsentService.Options
  ) {
    this.deps = deps;
    this.options = options;
    this.queue = new Queue({ concurrency: 1 });
    this.subject = new Subject();

    this.queue.add(async () => {
      try {
        const current = await this.deps.persistence.get(options.persistKey);
        if (!this.subject.value) this.subject.next(current || null);
      } catch (err) {
        this.deps.logger.error(err);
      }
    });

    into(this.subject, omit(null)).subscribe((state) => {
      if (state.analytics && options.analyticsJsUrl) {
        remote(options.analyticsJsUrl).catch((err) => {
          this.deps.logger.error(err);
        });
      }
    });
  }
  public get state$(): Push.Observable<Service.Consent.State | null> {
    return into(Observable.from(this.subject), compare('shallow'));
  }
  public update(state: Service.Consent.State | null): void {
    try {
      this.subject.next(state);
      this.deps.persistence.set(this.options.persistKey, state);
    } catch (err) {
      this.deps.logger.error(err);
    }
  }
}
