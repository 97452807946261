import React from 'react';
import { pipe } from 'pipettes';
import { useMachine } from 'supersour';

import { YoutubePlayerController } from './YoutubePlayer.controller';
import styles from './YoutubePlayer.module.scss';

export declare namespace YoutubePlayer {
  interface Props {
    videoId: string;
    start?: number;
    end?: number;
    onReady?: () => void;
    onPlay?: (play: boolean) => void;
  }
}

const PlayerMount = React.memo(
  (props: { id: string }): JSX.Element => <div id={props.id} />,
  () => true
);

export const YoutubePlayer = pipe(
  (props: YoutubePlayer.Props) => ({
    controller: useMachine(React, () => new YoutubePlayerController(props))
  }),
  ({ controller }) => (
    <div className={styles.root}>
      <PlayerMount id={controller.id} />
    </div>
  )
);
