import React from 'react';
import { Link } from 'framework7-react';
import { into } from 'pipettes';

import { Services, Utilities } from '../definitions';
import { Support } from '../Support';

interface ShareSegmentProps {
  utilities: Utilities;
  services: Services;
}

export const ShareSegment = into(
  (props: ShareSegmentProps): JSX.Element => {
    const { services, utilities } = props;
    return (
      <Link
        icon={utilities.icon.getClass('share')}
        onClick={services.share.share.bind(services.share)}
      />
    );
  },
  Support.withUtilities,
  Support.withServices
);
