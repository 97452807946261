import { Device, Support } from 'framework7';

import { Utility } from '../../components/definitions';

export class DeviceUtility implements Utility.Device {
  public isMobile(): boolean {
    return !Device.desktop;
  }
  public isPlatform(platform: Utility.Device.Platform): boolean {
    return Device[platform];
  }
  public hasFeature(feature: Utility.Device.Feature): boolean {
    switch (feature) {
      case 'mutationObserver': {
        return Support.observer;
      }
      case 'prefersColorTheme': {
        return Boolean(Device.prefersColorScheme());
      }
      default: {
        return Support[feature];
      }
    }
  }
}
