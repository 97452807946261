import { Push, combine, map } from 'multitude';
import { Consume, Create } from 'result-box';
import { into } from 'pipettes';
import titleize from 'titleize';

import { Repository } from '../definitions';
import { Routine, RoutineGetWithSessions } from './Routine.interactors';
import { Session, SessionGet } from './Session.interactors';

export class RoutineMappers {
  public static toDTO(routine: Repository.Routine.Structure): Routine.DTO {
    return {
      id: routine.id,
      name: routine.name,
      description: routine.description,
      level: RoutineMappers.levelToDTO(routine.level),
      targets: routine.targets.map(RoutineMappers.targetToDTO),
      sessionIds: routine.session_ids
    };
  }
  public static toWithSessionsDTOStream(
    routine: Repository.Routine.Structure,
    getSession: SessionGet
  ): Push.Observable<RoutineGetWithSessions.DTO> {
    const routineDTO = RoutineMappers.toDTO(routine);
    return into(
      combine(routine.session_ids.map((id) => getSession.use({ id }))),
      map((result) => Create.combine(result)),
      map((result) => Consume.result(result)),
      map((sessions) => {
        return sessions.filter((value) => Boolean(value)) as Session.DTO[];
      }),
      map((sessions) => ({
        ...routineDTO,
        sessions
      }))
    );
  }
  public static targetToDTO(target: string): Routine.DTO.Target {
    return {
      id: target,
      name: titleize(target)
    };
  }
  public static levelToDTO(level: string): Routine.DTO.Level {
    return RoutineMappers.targetToDTO(level);
  }
}
