import { Empty, ID } from 'type-core';
import { Create } from 'result-box';
import { into, pipe } from 'pipettes';
import { Observable, extract, map, switchMap } from 'multitude';

import { Interactor } from '@utils/interactor';
import { Failure } from '@utils/Failure';

import { Repository } from '../definitions';
import { RoutineMappers } from './Routine.mappers';
import { Session, SessionGet } from './Session.interactors';

/* Routine */
export declare namespace Routine {
  interface Deps {
    routine: Repository.Routine;
  }
  interface DTO {
    id: ID;
    name: string;
    description?: string;
    level: DTO.Level;
    targets: DTO.Target[];
    sessionIds: ID[];
  }
  namespace DTO {
    export type Level = { id: ID; name: string };
    export type Target = { id: ID; name: string };
  }
}

/* Find */
export declare namespace RoutineFind {
  type Deps = Routine.Deps;
  type Args = {
    filter?: { search?: string; levels?: ID[]; targets?: ID[] };
  };
  type DTO = Routine.DTO[];
}
export class RoutineFind extends Interactor<
  RoutineFind.Args,
  RoutineFind.DTO,
  Failure<'RoutineFind'>
> {
  public constructor(deps: RoutineFind.Deps) {
    super(
      ({ filter }) => {
        return into(
          deps.routine.find(
            filter
              ? {
                  search: filter.search,
                  levels: filter.levels ? filter.levels.map(String) : undefined,
                  targets: filter.targets
                    ? filter.targets.map(String)
                    : undefined
                }
              : undefined
          ),
          map((arr) => arr.map(RoutineMappers.toDTO)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'RoutineFind',
          message: 'There was an error retrieving routines',
          error,
          data: request
        });
      }
    );
  }
  public toggleManyTargets(targetId: ID): void {
    const args = into(this.request$, extract()) || {};
    if (!args.filter) args.filter = {};
    if (!args.filter.targets) args.filter.targets = [];

    if (args.filter.targets.includes(targetId)) {
      const targets = args.filter.targets.filter((x) => x !== targetId);
      this.use({
        ...args,
        filter: {
          ...args.filter,
          targets: targets.length ? targets : undefined
        }
      });
    } else {
      this.use({
        ...args,
        filter: {
          ...args.filter,
          targets: [...args.filter.targets, targetId]
        }
      });
    }
  }
  public toggleOneLevel(levelId: ID): void {
    const args = into(this.request$, extract()) || {};
    if (!args.filter) args.filter = {};
    if (!args.filter.levels) args.filter.levels = [];

    if (args.filter.levels.includes(levelId)) {
      this.use({ ...args, filter: { ...args.filter, levels: undefined } });
    } else {
      this.use({ ...args, filter: { ...args.filter, levels: [levelId] } });
    }
  }
}

/* Get */
export declare namespace RoutineGet {
  type Deps = Routine.Deps;
  type Args = { id: ID };
  type DTO = Routine.DTO | null;
}
export class RoutineGet extends Interactor<
  RoutineGet.Args,
  RoutineGet.DTO,
  Failure<'RoutineGet'>
> {
  public constructor(deps: RoutineGet.Deps) {
    super(
      ({ id }) => {
        return into(
          deps.routine.get(id),
          map((routine) => (routine ? RoutineMappers.toDTO(routine) : routine)),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'RoutineGet',
          message: 'There was an error retrieving the routine',
          error,
          data: request
        });
      }
    );
  }
}

/* GetWithSessions */
export declare namespace RoutineGetWithSessions {
  type Deps = Routine.Deps & { getSession: SessionGet };
  type Args = { id: ID };
  type DTO = Routine.DTO & { sessions: Session.DTO[] };
}
export class RoutineGetWithSessions extends Interactor<
  RoutineGetWithSessions.Args,
  RoutineGetWithSessions.DTO | null,
  Failure<'RoutineGetWithSession'>
> {
  public constructor(deps: RoutineGetWithSessions.Deps) {
    super(
      ({ id }) => {
        return into(
          deps.routine.get(id),
          switchMap((routine) => {
            return routine
              ? RoutineMappers.toWithSessionsDTOStream(routine, deps.getSession)
              : Observable.of(routine);
          }),
          map(Create.success)
        );
      },
      ({ error, request }) => {
        return Failure.from({
          label: 'RoutineGetWithSession',
          message: 'There was an error retrieving the routine',
          error,
          data: request
        });
      }
    );
  }
}

/* GetLevels */
export declare namespace RoutineGetLevels {
  type Deps = Routine.Deps;
  type DTO = Routine.DTO.Level[];
}
export class RoutineGetLevels extends Interactor<
  Empty,
  RoutineGetLevels.DTO,
  Failure<'RoutineGetLevels'>
> {
  public constructor(deps: RoutineGetLevels.Deps) {
    super(
      pipe(
        deps.routine.getLevels.bind(deps.routine),
        map((arr) => arr.map(RoutineMappers.levelToDTO)),
        map(Create.success)
      ),
      ({ error, request }) => {
        return Failure.from({
          label: 'RoutineGetLevels',
          message: 'There was an error retrieving routine difficulty levels',
          error,
          data: request
        });
      }
    );
  }
}

/* GetTargets */
export declare namespace RoutineGetTargets {
  type Deps = Routine.Deps;
  type DTO = Routine.DTO.Target[];
}
export class RoutineGetTargets extends Interactor<
  Empty,
  RoutineGetTargets.DTO,
  Failure<'RoutineGetTargets'>
> {
  public constructor(deps: RoutineGetTargets.Deps) {
    super(
      pipe(
        deps.routine.getTargets.bind(deps.routine),
        map((arr) => arr.map(RoutineMappers.targetToDTO)),
        map(Create.success)
      ),
      ({ error, request }) => {
        return Failure.from({
          label: 'RoutineGetTargets',
          message: 'There was an error retrieving the routines',
          error,
          data: request
        });
      }
    );
  }
}
