import { palettes } from 'variable-theming';

import { Utility } from '../components/definitions';

const themes: Record<Utility.Theme.Variant, Utility.Theme.Structure> = {
  main: {
    unit: {
      text: '1rem',
      space: '1rem',
      radius: '0.5rem'
    },
    typography: {
      primary: `Quicksand, "Helvetica Neue", Helvetica, Arial, sans-serif`,
      secondary: `Rubik, var(--typography-primary)`
    },
    palette: palettes({ tintBy: 0.2, shadeBy: 0.1 })({
      primary: { main: '#31d484', tint: '#7DE2A1', contrast: '#fafafa' },
      surface: { main: '#f9f9f9', contrast: '#27363d', accent: '#0000008a' },
      alternate: { main: '#00000066' },
      info: { main: '#2e2e2e', contrast: '#fafafa' },
      success: { main: '#31d484', contrast: '#ffffffd9' },
      warn: { main: '#fcdf75', contrast: '#2e2e2e' },
      danger: { main: '#ed7178', contrast: '#ffffff' },
      disable: { main: '#d9d9d9', contrast: '#929292' }
    }),
    shadow: {
      xs: '0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f',
      sm: '0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f',
      md: '0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d',
      lg: '0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a',
      xl: '0 25px 50px -20px #00000099',
      inset: 'inset 0 2px 4px 0 #0000000f'
    }
  }
};

export default themes;
