import { Service } from '../../components/definitions';

export declare namespace ShareService {
  interface Deps {
    notification: Service.Notification;
  }
  interface Options {
    defaults: () => Required<Service.Share.Structure>;
  }
}

interface ShareNavigator extends Navigator {
  share(options: { title: string; text: string; url: string }): Promise<any>;
}

// See: https://developer.mozilla.org/en-US/docs/Web/API
export class ShareService implements Service.Share {
  private deps: ShareService.Deps;
  private options: ShareService.Options;
  private navigator: null | ShareNavigator;
  public constructor(deps: ShareService.Deps, options: ShareService.Options) {
    this.deps = deps;
    this.options = options;
    this.navigator =
      typeof navigator !== 'undefined' &&
      ((navigator as any).share || navigator.clipboard)
        ? (navigator as any)
        : null;
  }
  public isAvailable = (): boolean => {
    return Boolean(this.navigator);
  };
  public async share(share?: Service.Share.Structure): Promise<void> {
    if (!this.navigator) {
      throw Error(`Web share and clipboard apis are unavailable`);
    }

    const opts = { ...this.options.defaults(), ...share };
    if (this.navigator.share) {
      await this.navigator.share(opts);
    } else {
      await this.navigator.clipboard.writeText(
        (opts.text || opts.title) + '\n' + opts.url
      );
      await this.deps.notification.push({
        type: 'feedback',
        title: 'Copied to clipboard',
        icon: 'clipboard'
      });
    }
  }
}
