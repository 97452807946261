import React from 'react';

import { Routine, Session } from '@application';

import {
  CardElement,
  CardFooterElement,
  CardHeaderElement
} from '../elements/CardElement';
import { BlockElement } from '../elements/BlockElement';
import { TitleElement } from '../elements/TitleElement';
import { WorkoutPlayerSegment } from '../segments/WorkoutPlayerSegment';

export declare namespace SessionScene {
  interface Props {
    session: Session.DTO;
    routine: Routine.DTO;
  }
}

export const SessionScene = (props: SessionScene.Props): JSX.Element => (
  <>
    <BlockElement left right>
      <TitleElement kind="medium">{props.routine.name}</TitleElement>
      <TitleElement kind="subtitle">
        {'Session ' +
          props.routine.sessionIds
            .map((x, i) => [x, i + 1])
            .filter(([x]) => x === props.session.id)[0][1]}
      </TitleElement>
    </BlockElement>
    {props.session.workouts.map((workout, i) => (
      <BlockElement key={i} separate xy>
        <CardElement>
          <CardHeaderElement
            title={workout.name}
            subtitle={'By ' + workout.instructor}
          />
          <WorkoutPlayerSegment workout={workout} />
          <CardFooterElement>
            <span>
              {workout.equipment.length
                ? workout.equipment.map((x) => x.name).join(', ')
                : 'No equipment'}
            </span>
            <span>{workout.minutes + ' min'}</span>
          </CardFooterElement>
        </CardElement>
      </BlockElement>
    ))}
  </>
);
