import React from 'react';
import { pipe } from 'pipettes';
import { cx, css } from 'emotion';
import { QRCode } from 'react-qr-svg';
import { useResource } from 'supersour';

import { Logo } from '../elements/Logo';
import { FrameController } from './Frame.controller';
import styles from './Frame.module.scss';

export declare namespace Frame {
  interface Props {
    breakpoint: number;
    appWidthPc?: number;
    appMaxWidthPx?: number;
    containerMaxWidthPx?: number;
    children: React.ReactNode;
  }
}

const variables = (
  vars: Partial<
    Record<'windowHeight' | 'appWidth' | 'appLeft' | 'infoWidth', number>
  >
): string => css`
  --frame-window-height: ${vars.windowHeight}px;
  --frame-app-width: ${vars.appWidth}px;
  --frame-app-left: ${vars.appLeft || 0}px;
  --frame-info-width: ${vars.infoWidth || 0}px;
`;

const href = window.location.href;
export const Frame = pipe(
  (props: Frame.Props) => ({
    ...props,
    controller: useResource(
      React,
      () =>
        new FrameController({
          breakpoint: props.breakpoint,
          appWidthPc: props.appWidthPc || 42.5,
          appMaxWidthPx: props.appMaxWidthPx || 425,
          containerMaxWidthPx: props.containerMaxWidthPx || 1200
        })
    )
  }),
  ({ controller, ...props }): JSX.Element | null => {
    if (!controller.state.isParent || controller.state.skipContainer) {
      return <div>{props.children}</div>;
    }

    return (
      <div className={cx(styles.container, variables(controller.state))}>
        <iframe title="application" src={href} className={styles.app} />
        <div className={styles.info}>
          <Logo width={225} variant="surface-main" className={styles.logo} />
          <div className={styles.box}>
            <QRCode
              value={controller.state.href}
              bgColor="transparent"
              fgColor="#000000"
              className={styles.qr}
            />
            <p>Scan to install on your device</p>
          </div>
        </div>
      </div>
    );
  }
);
