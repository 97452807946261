import React from 'react';
import { cx } from 'emotion';

import styles from './CardFooterElement.module.scss';

export declare namespace CardFooterElement {
  interface Props {
    children: React.ReactChild | React.ReactChild[];
    className?: string;
  }
}

export const CardFooterElement = (
  props: CardFooterElement.Props
): JSX.Element => (
  <div className={cx(styles.root, props.className)}>{props.children}</div>
);
