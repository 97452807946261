/* eslint-disable dot-notation */
import { ID } from 'type-core';
import { into } from 'pipettes';
import { LazyPromist } from 'promist';
import { Push, combine, fromPromise, map, switchMap } from 'multitude';

import { Repository } from '@application';

export class RoutineRepository implements Repository.Routine {
  private data: Promise<any>;
  public constructor(getData: () => Promise<any>) {
    this.data = LazyPromist.from(getData);
  }
  public get(id: ID): Push.Observable<Repository.Routine.Structure | null> {
    return into(
      fromPromise(this.data),
      map((data) => data.byId[id]),
      map((item) => item || null)
    );
  }
  public find(
    filters?: Repository.Routine.Filter
  ): Push.Observable<Repository.Routine.Structure[]> {
    return into(
      fromPromise(this.data),
      map((data) => Object.keys(data.byId)),
      switchMap((ids) => {
        return into(
          combine(ids.map((id) => this.get(id))),
          map((arr) => {
            return arr.filter((item): item is Repository.Routine.Structure => {
              return Boolean(item);
            });
          }),
          map((items) => {
            if (!filters) return items;

            const { search, levels, targets } = filters;

            if (levels) {
              items = items.filter((item) => levels.includes(item.level));
            }
            if (targets) {
              // item must have all targets
              items = items.filter((item) => {
                for (const selectedTarget of targets) {
                  if (!item.targets.includes(selectedTarget)) {
                    return false;
                  }
                }
                return true;
              });
            }

            if (search) {
              const lowerSearch = search.toLowerCase().trim();
              items = items.filter((item) => {
                return item.name.toLowerCase().includes(lowerSearch);
              });
            }

            return items;
          })
        );
      })
    );
  }
  public getLevels(): Push.Observable<string[]> {
    return into(
      fromPromise(this.data),
      map((data) => data.levels)
    );
  }
  public getTargets(): Push.Observable<string[]> {
    return into(
      fromPromise(this.data),
      map((data) => data.targets)
    );
  }
}
