import React from 'react';
import { ID } from 'type-core';
import { Operate } from 'result-box';
import { usePropsMulticast } from 'multitude';
import { Page, Navbar, NavRight, NavTitle } from 'framework7-react';

import { Repositories, RoutineGet, SessionGet, WorkoutGet } from '@application';
import { useComposite } from '@utils/interactor';

import { Router } from '../Router';
import { Support } from '../Support';
import { Logo } from '../elements/Logo';
import { Preloader } from '../framework/Preloader';
import { SessionScene } from '../scenes/SessionScene';
import { ShareSegment } from '../segments/ShareSegment';

export declare namespace SessionPage {
  interface Props {
    sessionId: ID;
    routineId: ID;
  }
}

interface SessionPageProps extends SessionPage.Props {
  repositories: Repositories;
}

export const SessionPage = Support.withRepositories<SessionPageProps>(
  (props) => {
    const { members, response } = useComposite(
      React,
      () => ({
        session: new SessionGet({
          session: props.repositories.session,
          getWorkout: new WorkoutGet(props.repositories)
        }),
        routine: new RoutineGet(props.repositories)
      }),
      Operate.transform(null, Support.setFailure),
      { flush: true }
    );

    usePropsMulticast(React, props, (props) => {
      members.routine.use({ id: props.routineId });
      members.session.use({ id: props.sessionId });
    });

    return (
      <Page>
        <Navbar
          backLink
          backLinkUrl={Router.getBackFromPathUrl({
            page: 'session',
            id: props.sessionId,
            routineId: props.routineId
          })}
        >
          <NavTitle>
            <Logo width={60} variant="surface-contrast" />
          </NavTitle>
          <NavRight>
            <ShareSegment />
          </NavRight>
        </Navbar>
        {response && response.routine && response.session ? (
          <SessionScene session={response.session} routine={response.routine} />
        ) : (
          <Preloader />
        )}
      </Page>
    );
  }
);
