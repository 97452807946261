import { SuperSource } from 'supersour';

export declare namespace MainPageController {
  interface State {
    tab: 'routines' | 'workouts';
    search?: string;
  }
}

export class MainPageController extends SuperSource<MainPageController.State> {
  public constructor() {
    super({ tab: 'routines' });
  }
  public handleSearchChange = (e: any): void => {
    this.next({ search: String(e.target.value) });
  };
  public handleSearchClear = (): void => {
    this.next({ search: undefined });
  };
  public handleRoutinesChange = (): void => {
    this.next({ tab: 'routines' });
  };
  public handleWorkoutsChange = (): void => {
    this.next({ tab: 'workouts' });
  };
}
