import { Empty, TypeGuard, UnaryFn } from 'type-core';
import {
  Push,
  Observable,
  catches,
  from,
  fromPromise,
  map,
  mergeMap,
  share,
  Subject,
  switchMap,
  throws
} from 'multitude';
import { into } from 'pipettes';

import { Hooks } from './helpers/Hooks';
import { InteractorType, Response } from './definitions';

export declare namespace Interactor {
  /* Options */
  interface Options {
    parallel?: boolean;
  }

  /* Recovery */
  type Recover<I, S, F> =
    | { times?: number; delay?: number }
    | UnaryFn<Context<I, S, F>, Push.Convertible<Response<S, F>>>;

  /* Context */
  interface Context<I, S, F> {
    error: Error;
    request: I;
    executor: UnaryFn<I, Push.Observable<Response<S, F>>>;
  }
}

export class Interactor<I, S, F> implements InteractorType<I, S, F> {
  #hooks: Hooks<I, S, F>;
  #executor: UnaryFn<I, Push.Observable<Response<S, F>>>;
  #failure: UnaryFn<Interactor.Context<I, S, F>, F>;
  #recover: Empty | Interactor.Recover<I, S, F>;
  #options: Interactor.Options;
  #request$: Push.Subject<I>;
  #response$: Push.Subject<Push.Observable<Response<S, F>>>;
  public constructor(
    executor: UnaryFn<I, Push.Convertible<Response<S, F>>>,
    failure: UnaryFn<Interactor.Context<I, S, F>, F>,
    recover?: Empty | Interactor.Recover<I, S, F>,
    options?: Interactor.Options | Empty
  ) {
    this.#hooks = new Hooks(this);

    this.#executor = (request) => from(executor(request));
    this.#failure = failure;
    this.#recover = recover;
    this.#options = options || {};

    this.#request$ = new Subject({ replay: true });
    this.#response$ = new Subject({ replay: true });
  }
  public get request$(): Push.Observable<I> {
    return Observable.from(this.#request$);
  }
  public get response$(): Push.Observable<Response<S, F>> {
    const mapper = this.#options.parallel ? mergeMap : switchMap;
    return into(
      this.#response$,
      mapper((observable) => observable),
      Observable.from
    );
  }
  public use(request: I): Push.Observable<Response<S, F>> {
    const hooks = this.#hooks;
    const executor = this.#executor;

    const observable = into(
      Observable.of(null),
      /* Execute */
      switchMap(() => {
        this.#request$.next(request);
        hooks.request(request);
        return executor(request);
      }),
      /* Recover */
      catches((err) => {
        const recover = this.#recover;
        if (!recover) return throws<Response<S, F>>(err);
        if (!TypeGuard.isRecord(recover)) {
          hooks.recover(err, request);
          return recover({ error: err, request, executor });
        }

        let i = 0;
        const times = recover.times || 0;
        const delay = recover.delay || 0;

        const retry = (error: Error): Push.Observable<Response<S, F>> => {
          if (times < 0 || i < times) {
            i++;
            return into(
              fromPromise(new Promise((resolve) => setTimeout(resolve, delay))),
              switchMap(() => {
                hooks.recover(error, request);
                return executor(request);
              }),
              catches(retry)
            );
          }
          return throws(error);
        };

        return retry(err);
      }),
      /* Failure */
      catches((error) => {
        const failure = this.#failure;
        return Observable.of({
          success: false as false,
          data: failure({ error, request, executor })
        });
      }),
      /* Response */
      map((response) => {
        hooks.response(response, request);
        return response;
      }),
      /* Error */
      catches((error) => {
        hooks.error(error, request);
        return throws<Response<S, F>>(error);
      }),
      share({ policy: 'on-demand', replay: true })
    );

    this.#response$.next(observable);
    return observable;
  }
}
