import { SuperMachine } from 'supersour';
import Youtube from 'youtube-player';
import { Subject } from 'multitude';
import { v4 as uuid } from 'uuid';

export declare namespace YoutubePlayerController {
  interface Deps {
    videoId: string;
    start?: number;
    end?: number;
    onReady?: () => void;
    onPlay?: (play: boolean) => void;
  }
}

export class YoutubePlayerController extends SuperMachine<YoutubePlayerController.Deps> {
  public id: string;
  private root: Subject<any>;
  private player: null | ReturnType<typeof Youtube>;
  public constructor(deps: YoutubePlayerController.Deps) {
    super(deps, () => {
      const timeout = setTimeout(() => this.initializePlayer(), 0);
      return () => {
        clearTimeout(timeout);
        if (this.player) this.player.destroy();
        this.player = null;
      };
    });
    this.id = uuid();
    this.root = new Subject({ replay: true });
    this.player = null;
  }
  public handleRef = (node: any): void => {
    this.root.next(node);
  };
  private initializePlayer(): void {
    this.player = Youtube(this.id, {
      videoId: this.deps.videoId,
      playerVars: {
        autoplay: 0,
        playsinline: 0,
        start: this.deps.start,
        end: this.deps.end
      }
    });

    this.player.on('ready', () => {
      if (this.deps.onReady) this.deps.onReady();
    });

    let latest: null | boolean = null;
    this.player.on('stateChange', (state) => {
      const play = state.data === 1;
      if (this.deps.onPlay && play !== latest) {
        latest = play;
        this.deps.onPlay(play);
      }
    });
  }
}
