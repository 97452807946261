import { SuperResource } from 'supersour';
import { wait } from 'promist';

import { Service } from '../definitions';

export declare namespace UpdateController {
  interface State {
    show: boolean;
    loading: boolean;
  }
  interface Deps {
    services: { worker: Service.Worker };
  }
}

export class UpdateController extends SuperResource<
  UpdateController.State,
  UpdateController.Deps
> {
  constructor(deps: UpdateController.Deps) {
    super({ show: false, loading: false }, deps, () => {
      return deps.services.worker.state$.subscribe((state) => {
        this.next({ show: state.updatePending });
      });
    });
  }
  public handleUpdate = async (): Promise<void> => {
    this.next({ loading: true });
    await this.deps.services.worker.update();
    await wait(500);
    window.location.reload();
  };
}
