import React from 'react';
import { into } from 'pipettes';
import { Consume } from 'result-box';
import { Push, compare, Subject, switchMap, useObservable } from 'multitude';

import { Repositories } from '@application';
import { Failure } from '@utils/Failure';

import { Configuration, Services, Utilities, Utility } from './definitions';

export class Support {
  private static fatal = new Subject<Error | Failure>({ replay: true });
  private static failure = new Subject<Error | Failure>({ replay: true });
  private static configuration = new Subject<Configuration>({ replay: true });
  private static utilities = new Subject<Utilities>({ replay: true });
  private static services = new Subject<Services>({ replay: true });
  private static repositories = new Subject<Repositories>({ replay: true });
  public static get fatal$(): Push.Observable<Error | Failure> {
    return into(Support.fatal, compare());
  }
  public static get failure$(): Push.Observable<Error | Failure> {
    return into(Support.failure, compare());
  }
  public static get configuration$(): Push.Observable<Configuration> {
    return into(Support.configuration, compare());
  }
  public static get utilities$(): Push.Observable<Utilities> {
    return into(Support.utilities, compare());
  }
  public static get services$(): Push.Observable<Services> {
    return into(Support.services, compare());
  }
  public static get repositories$(): Push.Observable<Repositories> {
    return into(Support.repositories, compare());
  }
  public static get theme$(): Push.Observable<Utility.Theme.Structure> {
    return into(
      Support.utilities,
      switchMap((utils) => utils.theme.theme$),
      compare()
    );
  }
  public static setFatal(failure: Error | Failure): void {
    Support.fatal.next(failure);
  }
  public static setFailure(failure: Error | Failure): void {
    Support.failure.next(failure);
  }
  public static setConfiguration(configuration: Configuration): void {
    Support.configuration.next(configuration);
  }
  public static setUtilities(utilities: Utilities): void {
    Support.utilities.next(utilities);
  }
  public static setServices(services: Services): void {
    Support.services.next(services);
  }
  public static setRepositories(repositories: Repositories): void {
    Support.repositories.next(repositories);
  }
  public static useFatal(): Error | Failure | null {
    return useObservable(React, Support.fatal$, Consume.result);
  }
  public static useFailure(): Error | Failure | null {
    return useObservable(React, Support.failure$, Consume.result);
  }
  public static useConfiguration(): Configuration | null {
    return useObservable(React, Support.configuration$, Consume.result);
  }
  public static useUtilities(): Utilities | null {
    return useObservable(React, Support.utilities$, Consume.result);
  }
  public static useServices(): Services | null {
    return useObservable(React, Support.services$, Consume.result);
  }
  public static useRepositories(): Repositories | null {
    return useObservable(React, Support.repositories$, Consume.result);
  }
  public static useTheme(): Utility.Theme.Structure | null {
    return useObservable(React, Support.theme$, Consume.result);
  }
  public static withFatal<P extends Record<'fatal', Error | Failure>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'fatal'>> {
    return (props) => {
      const fatal = Support.useFatal();
      if (!fatal) return null;
      return <Component {...(props as P)} fatal={fatal} />;
    };
  }
  public static withFailure<P extends Record<'failure', Error | Failure>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'failure'>> {
    return (props) => {
      const failure = Support.useFailure();
      if (!failure) return null;
      return <Component {...(props as P)} error={failure} />;
    };
  }
  public static withConfiguration<
    P extends Record<'configuration', Configuration>
  >(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'configuration'>> {
    return (props) => {
      const configuration = Support.useConfiguration();
      if (!configuration) return null;
      return <Component {...(props as P)} configuration={configuration} />;
    };
  }
  public static withUtilities<P extends Record<'utilities', Utilities>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'utilities'>> {
    return (props) => {
      const utilities = Support.useUtilities();
      if (!utilities) return null;
      return <Component {...(props as P)} utilities={utilities} />;
    };
  }
  public static withServices<P extends Record<'services', Services>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'services'>> {
    return (props) => {
      const services = Support.useServices();
      if (!services) return null;
      return <Component {...(props as P)} services={services} />;
    };
  }
  public static withRepositories<
    P extends Record<'repositories', Repositories>
  >(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'repositories'>> {
    return (props) => {
      const repositories = Support.useRepositories();
      if (!repositories) return null;
      return <Component {...(props as P)} repositories={repositories} />;
    };
  }
  public static withTheme<P extends Record<'theme', Utility.Theme.Structure>>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, 'theme'>> {
    return (props) => {
      const theme = Support.useTheme();
      if (theme) return null;
      return <Component {...(props as P)} services={theme} />;
    };
  }
}
