import { ID } from 'type-core';
import { into } from 'pipettes';
import { Consume, Create } from 'result-box';
import { Push, combine, map } from 'multitude';

import { Repository } from '../definitions';
import { Session } from './Session.interactors';
import { Workout, WorkoutGet } from './Workout.interactors';

export class SessionMappers {
  public static toDTOStream(
    session: Repository.Session.Structure,
    getWorkout: WorkoutGet
  ): Push.Observable<Session.DTO> {
    return into(
      SessionMappers.getWorkouts(session.workout_ids, getWorkout),
      map((workouts) => ({
        id: session.id,
        name: session.name,
        minutes: workouts.reduce(
          (acc, workout) => acc + (workout.minutes || 0),
          0
        ),
        workouts
      }))
    );
  }
  private static getWorkouts(
    ids: ID[],
    getWorkout: WorkoutGet
  ): Push.Observable<Workout.DTO[]> {
    const observables = ids.map((id) => getWorkout.use({ id }));
    return into(
      combine(observables),
      map((arr) => into(Create.combine(arr), Consume.result)),
      map<Array<Workout.DTO | null>, Array<Exclude<Workout.DTO, null>>>(
        (arr) => {
          return arr.filter((item): item is Workout.DTO => Boolean(item));
        }
      )
    );
  }
}
