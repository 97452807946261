import React from 'react';
import { ID } from 'type-core';
import { Operate } from 'result-box';
import { usePropsMulticast } from 'multitude';
import { Page, Navbar, NavRight, NavTitle } from 'framework7-react';

import {
  Repositories,
  RoutineGetWithSessions,
  SessionGet,
  WorkoutGet
} from '@application';
import { useInteractor } from '@utils/interactor';

import { Router } from '../Router';
import { Support } from '../Support';
import { Logo } from '../elements/Logo';
import { Preloader } from '../framework/Preloader';
import { ShareSegment } from '../segments/ShareSegment';
import { RoutineScene } from '../scenes/RoutineScene';

export declare namespace RoutinePage {
  interface Props {
    routineId: ID;
  }
}

interface RoutinePageProps extends RoutinePage.Props {
  repositories: Repositories;
}

export const RoutinePage = Support.withRepositories<RoutinePageProps>(
  (props) => {
    const { interactor, response } = useInteractor(
      React,
      () => {
        return new RoutineGetWithSessions({
          routine: props.repositories.routine,
          getSession: new SessionGet({
            session: props.repositories.session,
            getWorkout: new WorkoutGet(props.repositories)
          })
        });
      },
      Operate.transform(null, Support.setFailure),
      { flush: true }
    );

    usePropsMulticast(React, props.routineId, (id) => interactor.use({ id }));

    return (
      <Page>
        <Navbar
          backLink
          backLinkUrl={Router.getBackFromPathUrl({
            page: 'routine',
            id: props.routineId
          })}
        >
          <NavTitle>
            <Logo width={60} variant="surface-contrast" />
          </NavTitle>
          <NavRight>
            <ShareSegment />
          </NavRight>
        </Navbar>
        <Page>
          <Navbar
            backLink
            backLinkUrl={Router.getBackFromPathUrl({
              page: 'routine',
              id: props.routineId
            })}
          >
            <NavTitle>
              <Logo width={60} variant="surface-contrast" />
            </NavTitle>
            <NavRight>
              <ShareSegment />
            </NavRight>
          </Navbar>
          {response ? <RoutineScene routine={response} /> : <Preloader />}
        </Page>
      </Page>
    );
  }
);
