import React from 'react';
import { cx } from 'emotion';

import styles from './BlockElement.module.scss';

export declare namespace BlockElement {
  interface Props {
    children: React.ReactChild | React.ReactChild[];
    row?: boolean;
    column?: boolean;
    separate?: boolean;
    xy?: boolean;
    top?: boolean;
    right?: boolean;
    bottom?: boolean;
    left?: boolean;
    className?: string;
  }
}

export const BlockElement = (props: BlockElement.Props): JSX.Element => (
  <div
    className={cx(styles.root, props.className, {
      [styles.row]: props.row,
      [styles.column]: props.column,
      [styles.separate]: props.separate,
      [styles.xy]: props.xy,
      [styles.top]: props.top,
      [styles.right]: props.right,
      [styles.bottom]: props.bottom,
      [styles.left]: props.left
    })}
  >
    {props.children}
  </div>
);
