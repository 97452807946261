/* eslint-disable dot-notation */
import { ID } from 'type-core';
import { into } from 'pipettes';
import { LazyPromist } from 'promist';
import { Push, combine, fromPromise, map, switchMap } from 'multitude';

import { Repository } from '@application';

export class SessionRepository implements Repository.Session {
  private data: Promise<any>;
  public constructor(getData: () => Promise<any>) {
    this.data = LazyPromist.from(getData);
  }
  public get(id: ID): Push.Observable<Repository.Session.Structure | null> {
    return into(
      fromPromise(this.data),
      map((data) => data.byId[id]),
      map((item) => item || null)
    );
  }
  public find(
    filters: Repository.Session.Filter
  ): Push.Observable<Repository.Session.Structure[]> {
    if (filters && filters.ids) {
      return into(
        combine(filters.ids.map((id) => this.get(id))),
        map<
          Array<Repository.Session.Structure | null>,
          Repository.Session.Structure[]
        >((sessions) => {
          return sessions.filter(
            (session): session is Repository.Session.Structure => {
              return Boolean(session);
            }
          );
        })
      );
    }

    return into(
      fromPromise(this.data),
      map((data) => Object.keys(data.byId)),
      switchMap((ids) => {
        return into(
          combine(ids.map((id) => this.get(id))),
          map((arr) => {
            return arr.filter((item): item is Repository.Session.Structure => {
              return Boolean(item);
            });
          })
        );
      })
    );
  }
}
