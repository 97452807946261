import { Utility } from '../../components/definitions';

export declare namespace BreakpointUtility {
  interface Options {
    breakpoints: Record<Utility.Breakpoint.Variant, number>;
  }
}

export class BreakpointUtility implements Utility.Breakpoint {
  private options: BreakpointUtility.Options;
  public constructor(options: BreakpointUtility.Options) {
    this.options = options;
  }
  public value(variant: Utility.Breakpoint.Variant): number {
    return this.options.breakpoints[variant];
  }
  public from(from: Utility.Breakpoint.Variant): string {
    const breakpoint = this.options.breakpoints[from];
    return `@media (min-width: ${breakpoint}px)`;
  }
}
