import React, { useState } from 'react';
import { View } from 'framework7-react';
import { TypeGuard } from 'type-core';
import { into, pipe } from 'pipettes';
import { Consume, Operate } from 'result-box';
import { switchMap, take, map, useObservable } from 'multitude';

import { Configuration, Utilities } from './definitions';
import { Frame } from './framework/Frame';
import { Application } from './framework/Application';
import { LoadFadeIn } from './framework/LoadFadeIn';
import { LoadingBar } from './framework/LoadingBar';
import { Consent } from './framework/Consent';
import { PWAPrompt } from './framework/PWAPrompt';
import { Update } from './framework/Update';
import { Support } from './Support';
import { Router } from './Router';

export declare namespace App {
  interface Props {
    configuration: Configuration;
    utilities: Utilities;
  }
}

export const App = into(
  pipe(
    (props: App.Props) => ({
      ...props,
      assetsReady: useState(false),
      repositoriesReady: useObservable(
        React,
        () => {
          return into(
            Support.repositories$,
            take(1),
            map(() => true)
          );
        },
        pipe(Operate.fallback(false), Consume.result)
      ),
      consent: useObservable(
        React,
        () => {
          return into(
            Support.services$,
            switchMap((services) => services.consent.state$)
          );
        },
        Consume.result
      )
    }),
    ({ configuration, utilities, ...props }): JSX.Element => {
      return (
        <LoadFadeIn onLoad={() => props.assetsReady[1](true)}>
          <Frame breakpoint={utilities.breakpoint.value('lg')}>
            <Application
              params={{
                id: configuration.app.id,
                name: configuration.app.name,
                routes: Router.routes,
                theme: 'auto',
                dialog: {
                  buttonOk: 'Continue',
                  buttonCancel: 'Cancel',
                  preloaderTitle: 'Loading...',
                  progressTitle: 'Loading...'
                }
              }}
            >
              <LoadingBar
                active={!props.assetsReady[0] || !props.repositoriesReady}
              />
              <View
                main
                pushState
                pushStateSeparator=""
                animate={utilities.device.isMobile()}
              />
              <Consent shouldOpen={TypeGuard.isNull(props.consent)} />
              <Update
                message={`A ${configuration.app.name} update is now ready.`}
              />
              <PWAPrompt
                message={`Add ${configuration.app.name} to your home screen for easy access and a better experience.`}
                times={props.consent ? 3 : 0}
                delay={1500}
                debug={false}
              />
            </Application>
          </Frame>
        </LoadFadeIn>
      );
    }
  ),
  Support.withConfiguration,
  Support.withUtilities
);
