import React from 'react';
import { cx } from 'emotion';
import { Link } from 'framework7-react';

import styles from './CardElement.module.scss';

export declare namespace CardElement {
  interface Props {
    href?: string;
    children?: React.ReactChild | React.ReactChild[];
    className?: string;
  }
}

export const CardElement = (props: CardElement.Props): JSX.Element => {
  return (
    <Link
      href={props.href}
      noLinkClass={!props.href}
      className={cx(styles.root, props.className, {
        [styles.link]: Boolean(props.href)
      })}
    >
      <div className={styles.card}>{props.children}</div>
    </Link>
  );
};
