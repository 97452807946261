import { into } from 'pipettes';
import Framework7 from 'framework7/framework7.esm.bundle';
import Framework7React, { f7, f7ready } from 'framework7-react';

import { render } from '../components';
import { utilities } from './utilities';
import { services } from './services';
import { repositories } from './repositories';
import env from './env';

export function main(): void {
  Framework7.use(Framework7React);

  const framework7 = new Promise<Framework7>((resolve) => {
    f7ready(() => resolve(f7));
  });
  const deps = into(
    {
      f7: framework7,
      configuration: {
        env: env.env,
        app: {
          id: env.appId,
          name: env.manifest.name,
          description: env.manifest.description
        }
      },
      utilities: utilities()
    },
    (deps) => ({
      ...deps,
      services: services(deps),
      repositories: repositories()
    })
  );

  render(deps);

  Promise.all([deps.utilities, deps.services]).then(
    ([utilities, services]) => {
      Object.assign(window, { app: { utilities, services } });
    },
    () => null
  );
}
