import React from 'react';
import PWAPromptiOS from 'react-ios-pwa-prompt';

import styles from './PWAPrompt.module.scss';

export declare namespace PWAPrompt {
  interface Props {
    message: string;
    times: number;
    delay: number;
    debug: boolean;
  }
}

export const PWAPrompt = (props: PWAPrompt.Props): JSX.Element => (
  <div className={styles.root}>
    <PWAPromptiOS
      copyBody={props.message}
      timesToShow={props.times}
      delay={props.delay}
      debug={props.debug}
    />
  </div>
);
