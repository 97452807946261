import titleize from 'titleize';

import { Repository } from '../definitions';
import { Workout } from './Workout.interactors';

export class WorkoutMappers {
  public static toDTO(workout: Repository.Workout.Structure): Workout.DTO {
    return {
      id: workout.id,
      name: workout.name,
      description: workout.description,
      instructor: workout.instructor,
      intensity: WorkoutMappers.intensityToDTO(workout.intensity),
      category: WorkoutMappers.categoryToDTO(workout.category),
      targets: workout.targets.map(WorkoutMappers.targetToDTO),
      equipment: workout.equipment.map(WorkoutMappers.equipmentToDTO),
      minutes: workout.minutes,
      videoUrl: workout.video_url,
      startTime: workout.start_time,
      endTime: workout.end_time
    };
  }
  public static intensityToDTO(intensity: string): Workout.DTO.Intensity {
    return {
      id: intensity,
      name: titleize(intensity)
    };
  }
  public static categoryToDTO(category: string): Workout.DTO.Category {
    return WorkoutMappers.intensityToDTO(category);
  }
  public static targetToDTO(target: string): Workout.DTO.Target {
    return WorkoutMappers.intensityToDTO(target);
  }
  public static equipmentToDTO(equipment: string): Workout.DTO.Equipment {
    return WorkoutMappers.intensityToDTO(equipment);
  }
}
