import {
  Push,
  Observable,
  compare,
  map,
  merge,
  share,
  skip,
  start
} from 'multitude';
import { into } from 'pipettes';

import { InteractorType, Response } from '../definitions';

export function flush<T extends InteractorType>(
  interactor: T
): Push.Observable<Response<T> | null> {
  return into(
    merge(
      into(
        interactor.request$,
        map(() => null),
        skip(1)
      ),
      interactor.response$
    ),
    compare((before, current) => before === null && before === current),
    start(null, 'no-emit'),
    share({ policy: 'on-demand', replay: true }),
    Observable.from
  );
}
