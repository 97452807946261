import { ID } from 'type-core';
import { SuperResource } from 'supersour';
import { Push, debounce, extract } from 'multitude';
import { Operate, Result } from 'result-box';
import { into } from 'pipettes';

import {
  Repositories,
  Routine,
  RoutineFind,
  RoutineGetLevels,
  RoutineGetTargets
} from '@application';
import { composite } from '@utils/interactor';
import { Failure } from '@utils/Failure';

import { Support } from '../Support';

export declare namespace RoutinesSceneController {
  interface State {
    routines: Routine.DTO[];
    targets: Record<ID, { name: string; active: boolean }>;
    levels: Record<ID, { name: string; active: boolean }>;
  }
  interface Deps {
    search$: Push.Observable<string | undefined>;
    repositories: Repositories;
  }
}

export class RoutinesSceneController extends SuperResource<
  Result.Break<RoutinesSceneController.State, Failure>,
  RoutinesSceneController.Deps
> {
  private interactors: {
    find: RoutineFind;
    targets: RoutineGetTargets;
    levels: RoutineGetLevels;
  };
  public constructor(deps: RoutinesSceneController.Deps) {
    super(null, deps, () => {
      this.interactors.targets.use(null);
      this.interactors.levels.use(null);

      return [
        into(deps.search$, debounce(250)).subscribe((search) => {
          this.interactors.find.use({ filter: { search } });
        }),
        composite(this.interactors, { flush: true }).subscribe(
          (combination) => {
            const result = into(
              combination,
              Operate.tap(null, Support.setFatal),
              Operate.map((result) => {
                const state: RoutinesSceneController.State = {
                  routines: result.find,
                  targets: {},
                  levels: {}
                };
                for (const target of result.targets) {
                  state.targets[target.id] = {
                    name: target.name,
                    active: this.isTargetActive(target.id)
                  };
                }
                for (const level of result.levels) {
                  state.levels[level.id] = {
                    name: level.name,
                    active: this.isLevelActive(level.id)
                  };
                }

                return state;
              })
            );

            this.next(result);
          }
        )
      ];
    });

    this.interactors = {
      find: new RoutineFind(deps.repositories),
      targets: new RoutineGetTargets(deps.repositories),
      levels: new RoutineGetLevels(deps.repositories)
    };
  }
  public toggleManyTargets = (targetId: ID): void => {
    this.interactors.find.toggleManyTargets(targetId);
  };
  public toggleOneLevel = (levelId: ID): void => {
    this.interactors.find.toggleOneLevel(levelId);
  };
  private isTargetActive(targetId: ID): boolean {
    const args = into(this.interactors.find.request$, extract());
    if (!args || !args.filter || !args.filter.targets) return false;
    return args.filter.targets.includes(targetId);
  }
  private isLevelActive(levelId: ID): boolean {
    const args = into(this.interactors.find.request$, extract());
    if (!args || !args.filter || !args.filter.levels) return false;
    return args.filter.levels.includes(levelId);
  }
}
