import React from 'react';
import { into } from 'pipettes';
import { Operate } from 'result-box';
import { useResource } from 'supersour';
import { usePropsMulticast } from 'multitude';

import { Repositories } from '@application';

import { Services } from '../definitions';
import { Router } from '../Router';
import { Support } from '../Support';
import { Preloader } from '../framework/Preloader';
import { ChipSelect } from '../elements/ChipSelect';
import {
  CardElement,
  CardHeaderElement,
  CardFooterElement
} from '../elements/CardElement';
import { TitleElement } from '../elements/TitleElement';
import { BlockElement } from '../elements/BlockElement';
import { WorkoutsSceneController } from './WorkoutsScene.controller';

export declare namespace WorkoutsScene {
  interface Props {
    search?: string;
  }
}

interface Props extends WorkoutsScene.Props {
  repositories: Repositories;
  services: Services;
}

export const WorkoutsScene = into(
  (props: Props) => {
    const search$ = usePropsMulticast(React, props.search);

    const controller = useResource(React, () => {
      return new WorkoutsSceneController({
        search$,
        workout: props.repositories.workout,
        notifications: props.services.notification
      });
    });

    return into(
      controller.state,
      Operate.map(null, () => null),
      Operate.transform(),
      (state) => {
        if (!state) return <Preloader />;

        return (
          <>
            <BlockElement left right>
              <TitleElement kind="large">
                <span>Choose your</span>
                <strong>Workout</strong>
              </TitleElement>
            </BlockElement>
            <ChipSelect
              items={state.targets}
              onSelect={controller.toggleManyTargets}
            />
            <ChipSelect
              items={state.categories}
              onSelect={controller.toggleManyCategories}
            />
            <BlockElement left right>
              <TitleElement kind="section">
                {state.workouts.length ? 'Workouts' : 'No workouts'}
              </TitleElement>
            </BlockElement>
            <BlockElement column left right bottom>
              {state.workouts.map((workout) => (
                <CardElement
                  key={workout.id}
                  href={Router.getPathUrl({ page: 'workout', id: workout.id })}
                >
                  <CardHeaderElement
                    title={workout.name}
                    chip={{
                      items: [
                        workout.targets.length > 1
                          ? 'Multi'
                          : workout.targets[0].name,
                        workout.category.name
                      ]
                    }}
                  />
                  <CardFooterElement>
                    <span>{'By ' + workout.instructor}</span>
                    <span>{workout.minutes + ' min'}</span>
                  </CardFooterElement>
                </CardElement>
              ))}
            </BlockElement>
          </>
        );
      }
    );
  },
  Support.withRepositories,
  Support.withServices
);
