import { Empty, Members, NullaryFn, UnaryFn } from 'type-core';
import {
  CompareStrategy,
  ReactHooksDependency,
  compare,
  useObservable
} from 'multitude';
import { into, pipe } from 'pipettes';
import { Consume } from 'result-box';

import { InteractorType, ResponseType } from '../definitions';
import { composite, Composite } from './composite';
import { flush } from './flush';

type InteractorResponseType<T extends InteractorType> = ResponseType<T>;

export declare namespace ReactHook {
  interface Options {
    flush?: boolean;
    compare?: CompareStrategy;
  }

  type ResponseType<
    T extends InteractorType | Members<InteractorType>
  > = T extends InteractorType
    ? InteractorResponseType<T>
    : T extends Members<InteractorType>
    ? Composite.ResponseType<T>
    : never;
}

export function useInteractor<
  T extends InteractorType,
  U = ReactHook.ResponseType<T> | null
>(
  React: ReactHooksDependency,
  create: NullaryFn<T>,
  projection?: UnaryFn<ReactHook.ResponseType<T> | null, U> | Empty,
  options?: ReactHook.Options
): { interactor: T; response: U } {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const interactor = React.useMemo(() => create(), []);

  const response: any = useObservable(
    React,
    () => {
      const opts = options || {};
      return into(
        opts.flush ? flush(interactor) : interactor.request$,
        compare(opts.compare)
      );
    },
    pipe(Consume.result, projection || ((x) => x))
  );

  return { interactor, response };
}

export function useComposite<
  T extends Members<InteractorType>,
  U = ReactHook.ResponseType<T> | null
>(
  React: ReactHooksDependency,
  create: NullaryFn<T>,
  projection?: UnaryFn<ReactHook.ResponseType<T> | null, U> | Empty,
  options?: ReactHook.Options
): { members: T; response: U } {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const members = React.useMemo(() => create(), []);

  const response: any = useObservable(
    React,
    () => {
      const opts = options || {};
      return into(
        composite(members, { flush: opts.flush }),
        compare(opts.compare)
      );
    },
    pipe(Consume.result as any, projection || ((x) => x))
  );

  return { members, response };
}
