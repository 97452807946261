import Framework7 from 'framework7';
import { into } from 'pipettes';

import { Services, Utilities } from '../components/definitions';
import {
  ConsentService,
  NotificationService,
  PersistenceService,
  PreloaderService,
  ShareService,
  WorkerService
} from '../instruments';
import env from './env';

interface ServicesDeps {
  f7: Promise<Framework7>;
  utilities: Utilities;
}

export async function services(deps: ServicesDeps): Promise<Services> {
  return into(
    {
      persistence: new PersistenceService(),
      notification: new NotificationService(
        { ...deps.utilities, f7: deps.f7 },
        { feedbackCloseMs: 1250 }
      )
    },
    (services) => ({
      ...services,
      consent: new ConsentService(
        { ...services, ...deps.utilities },
        {
          persistKey: '__consent_service__',
          analyticsJsUrl:
            env.env === 'development' ? null : `${env.publicUrl}/analytics.js`
        }
      ),
      worker: new WorkerService(deps.utilities, {
        enable: env.serviceWorker,
        publicUrl: env.publicUrl
      }),
      share: new ShareService(services, {
        defaults: () => ({
          title: env.manifest.name,
          text: env.manifest.description,
          url: window.location.href
        })
      }),
      preloader: new PreloaderService(deps)
    })
  );
}
