import React from 'react';
import { cx, css } from 'emotion';
import { pipe } from 'pipettes';

import styles from './ChipElement.module.scss';

export declare namespace ChipElement {
  interface Props {
    items: string[];
    href?: string;
    palette?: string;
    invert?: boolean;
    shadow?: boolean;
    even?: { color: string; background: string };
    odd?: { color: string; background: string };
    onClick?: () => void;
    className?: string;
  }
}

interface Colors {
  color: string;
  background: string;
}

const variables = (odd: Colors, even: Colors): string => css`
  --chip-element-odd-color: ${odd.color};
  --chip-element-odd-background: ${odd.background};
  --chip-element-even-color: ${even.color};
  --chip-element-even-background: ${even.background};
`;

export const ChipElement = pipe(
  (props: ChipElement.Props) => ({
    odd: {
      color: 'var(--palette-surface-contrast)',
      background: 'var(--palette-surface-tint)'
    },
    even: {
      color: 'var(--palette-surface-contrast)',
      background: 'var(--palette-surface-shade)'
    },
    ...props
  }),
  (props): JSX.Element => (
    <div
      className={cx(
        styles.root,
        props.className,
        props.invert
          ? variables(props.even, props.odd)
          : variables(props.odd, props.even),
        {
          [styles.shadow]: props.shadow
        }
      )}
    >
      <div onClick={props.onClick} className={styles.box}>
        {props.items.map((item, i) => (
          <span key={i} className={styles.item}>
            <span className={styles.fill} />
            <span className={styles.text}>{item}</span>
          </span>
        ))}
      </div>
    </div>
  )
);
