import React from 'react';
import { css, cx } from 'emotion';
import { Transition } from 'react-transition-group';

import styles from './FadeElement.module.scss';

export declare namespace FadeElement {
  interface Props {
    in: boolean;
    duration: number;
    className?: string;
    children?: React.ReactNode;
  }
}

const variables = (duration: number): string => css`
  --fade-element-duration: ${duration}ms;
`;

export const FadeElement = (props: FadeElement.Props): JSX.Element => (
  <Transition in={props.in} timeout={props.duration}>
    {() => (
      <div
        className={cx(styles.root, props.className, variables(props.duration), {
          [styles.in]: props.in
        })}
      >
        {props.children}
      </div>
    )}
  </Transition>
);
