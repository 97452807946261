import React from 'react';
import { css, cx } from 'emotion';

import surfaceMain from '@assets/logo-surface-main.png';
import surfaceContrast from '@assets/logo-surface-contrast.png';
import primaryMain from '@assets/logo-primary-main.png';

import styles from './Logo.module.scss';

export declare namespace Logo {
  interface Props {
    width: number;
    variant: 'surface-main' | 'surface-contrast' | 'primary-main';
    className?: string;
  }
}

const variants: Record<Logo.Props['variant'], string> = {
  'surface-main': surfaceMain,
  'surface-contrast': surfaceContrast,
  'primary-main': primaryMain
};

const variables = (width: number): string => css`
  --logo-width: ${width}px;
`;

export const Logo = (props: Logo.Props): JSX.Element => (
  <div className={cx(styles.root, props.className, variables(props.width))}>
    <img src={variants[props.variant]} alt="Logo" />
  </div>
);
