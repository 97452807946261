import React from 'react';
import { into } from 'pipettes';
import { Operate } from 'result-box';
import { useResource } from 'supersour';
import { usePropsMulticast } from 'multitude';

import { Repositories } from '@application';

import { Router } from '../Router';
import { Support } from '../Support';
import { Preloader } from '../framework/Preloader';
import { ChipSelect } from '../elements/ChipSelect';
import {
  CardElement,
  CardHeaderElement,
  CardFooterElement
} from '../elements/CardElement';
import { TitleElement } from '../elements/TitleElement';
import { BlockElement } from '../elements/BlockElement';
import { RoutinesSceneController } from './RoutinesScene.controller';

export declare namespace RoutinesScene {
  interface Props {
    search?: string;
  }
}

interface Props extends RoutinesScene.Props {
  repositories: Repositories;
}

export const RoutinesScene = Support.withRepositories((props: Props) => {
  const search$ = usePropsMulticast(React, props.search);
  const controller = useResource(React, () => {
    return new RoutinesSceneController({
      search$,
      repositories: props.repositories
    });
  });

  return into(
    controller.state,
    Operate.map(null, () => null),
    Operate.transform(),
    (state) => {
      if (!state) return <Preloader />;

      return (
        <>
          <BlockElement left right>
            <TitleElement kind="large">
              <span>Choose your </span>
              <strong>Routine</strong>
            </TitleElement>
          </BlockElement>
          <ChipSelect
            items={state.targets}
            onSelect={controller.toggleManyTargets}
          />
          <ChipSelect
            items={state.levels}
            onSelect={controller.toggleOneLevel}
          />
          <BlockElement left right>
            <TitleElement kind="section">
              {state.routines.length ? 'Routines' : 'No routines'}
            </TitleElement>
          </BlockElement>
          <BlockElement column left right bottom>
            {state.routines.map((routine) => (
              <CardElement
                key={routine.id}
                href={Router.getPathUrl({ page: 'routine', id: routine.id })}
              >
                <CardHeaderElement
                  title={routine.name}
                  chip={{
                    items: [
                      routine.targets.length > 1
                        ? 'Multi'
                        : routine.targets[0].name,
                      routine.level.name
                    ]
                  }}
                />
                <CardFooterElement>
                  <span>{routine.sessionIds.length + ' sessions'}</span>
                </CardFooterElement>
              </CardElement>
            ))}
          </BlockElement>
        </>
      );
    }
  );
});
