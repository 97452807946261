import { SuperSource } from 'supersour';

export declare namespace WorkoutPlayerSegmentController {
  interface State {
    ready: boolean;
    play: boolean;
  }
}

export class WorkoutPlayerSegmentController extends SuperSource<WorkoutPlayerSegmentController.State> {
  public constructor() {
    super({ ready: false, play: false });
  }
  public handleReady = (): void => {
    this.next({ ready: true });
  };
  public handlePlay = (play: boolean): void => {
    this.next({ play });
  };
}
