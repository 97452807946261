import { NullaryFn } from 'type-core';
import Framework7 from 'framework7';
import Queue from 'p-queue';

import { Service } from '../../components/definitions';

export declare namespace PreloaderService {
  interface Deps {
    f7: Promise<Framework7>;
  }
}

export class PreloaderService implements Service.Preloader {
  private deps: PreloaderService.Deps;
  private queue: Queue;
  private active: number;
  private isHidden: boolean;
  public constructor(deps: PreloaderService.Deps) {
    this.deps = deps;
    this.active = 0;
    this.queue = new Queue({ concurrency: 1 });
    this.isHidden = true;
  }
  public start(): NullaryFn {
    this.active++;
    this.queue.add(() => this.process());
    return () => {
      this.active--;
      this.queue.add(() => this.process());
    };
  }
  private async process(): Promise<void> {
    if (this.active > 0) {
      if (!this.isHidden) return;

      this.isHidden = false;
      (await this.deps.f7).preloader.show();
    } else {
      if (this.isHidden) return;

      this.isHidden = true;
      (await this.deps.f7).preloader.hide();
    }
  }
}
