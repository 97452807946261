import { getLogger, Logger, LogLevelDesc } from 'loglevel';
import { v4 as uuid } from 'uuid';

import { Utility } from '../../components/definitions';

export declare namespace LoggerUtility {
  interface Options {
    level: LogLevelDesc;
    scope?: string;
  }
}

export class LoggerUtility implements Utility.Logger {
  private logger: Logger;
  private scope: string | null;
  public constructor(options: LoggerUtility.Options) {
    this.logger = getLogger(uuid());
    this.scope = options.scope || null;

    this.logger.setLevel(options.level);
  }
  public trace(data: string | object, message?: string): void {
    return this.logger.trace(...this.map(data, message));
  }
  public debug(data: string | object, message?: string): void {
    return this.logger.debug(...this.map(data, message));
  }
  public info(data: string | object, message?: string): void {
    return this.logger.info(...this.map(data, message));
  }
  public warn(data: string | object, message?: string): void {
    return this.logger.warn(...this.map(data, message));
  }
  public error(data: string | object, message?: string): void {
    return this.logger.error(...this.map(data, message));
  }
  public fatal(data: string | object, message?: string): void {
    return this.logger.error(...this.map(data, message));
  }
  private map(
    data: string | object,
    message?: string
  ): [string | object] | [string, string | object] {
    if (this.scope) {
      return message
        ? [`[${this.scope}] ${message}`, data]
        : [`[${this.scope}]`, data];
    }
    return message ? [message, data] : [data];
  }
}
