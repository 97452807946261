/* Assets */
import 'boxicons/css/boxicons.css';

import { Utility } from '../../components/definitions';

export declare namespace IconUtility {
  interface Deps {
    logger: Utility.Logger;
  }
}

export class IconUtility implements Utility.Icon {
  private deps: IconUtility.Deps;
  private icons: { [P in Utility.Icon.Variant]: string };
  public constructor(deps: IconUtility.Deps) {
    this.deps = deps;
    this.icons = {
      routines: 'bx bx-collection',
      workouts: 'bx bx-dumbbell',
      clipboard: 'bx bx-clipboard',
      share: 'bx bx-share-alt',
      search: 'bx bx-search'
    };
  }
  public getClass(variant: Utility.Icon.Variant): string {
    const icon = this.icons[variant];
    if (!icon) {
      this.deps.logger.error(`invalid icon request: ${variant}`);
      return '';
    }
    return icon;
  }
}
