import React from 'react';
import {
  Actions,
  ActionsGroup,
  ActionsLabel,
  ActionsButton
} from 'framework7-react';
import { useResource } from 'supersour';

import { Services } from '../definitions';
import { Support } from '../Support';
import { UpdateController } from './Update.controller';
import { Preloader } from './Preloader';

export declare namespace Update {
  interface Props {
    message: string;
  }
}

interface UpdateProps extends Update.Props {
  services: Services;
}

export const Update = Support.withServices((props: UpdateProps) => {
  const controller = useResource(React, () => new UpdateController(props));

  return (
    <>
      {controller.state.loading && <Preloader />}
      <Actions
        opened={controller.state.show}
        closeByOutsideClick={false}
        closeByBackdropClick={false}
        onActionsClosed={() => null}
      >
        <ActionsGroup>
          <ActionsLabel>{props.message}</ActionsLabel>
          <ActionsButton onClick={controller.handleUpdate}>
            Continue
          </ActionsButton>
        </ActionsGroup>
      </Actions>
    </>
  );
});
